import { auth } from "firebase-config";
import { onSnapshot, doc, Unsubscribe } from "firebase/firestore";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCallSignAvailability,
  checkUserProfile,
  CoordinatesParams,
  editUserProfile,
  fetchUserAwards,
  handleCoordinatesLoadingStatus,
  saveCoordinates,
  setFeaturedUserAward,
  storeLoggedInUser,
  updateUserProfile,
  setUserAvatarAction,
} from "store/Actions/profile.actions";
import {
  getIsFetchingCallSignAvailabilitySelector,
  getIsFetchingGuestUserProfileSelector,
  getisGettingLocationSelector,
  getIsProfileLoadingSelector,
  getIsUserAwardLoadingSelector,
} from "store/Reducers/loading.reducer";
import {
  getIsCallSignAvailableSelector,
  getUserProfileSelector,
  getUserAwardsSelector,
  getUserAvatarSelector,
} from "store/Reducers/profile.reducer";
import { DXCCList, UserAwards, UserProfile } from "types/Models";
import { db } from "firebase-config";
import { UserDataTable } from "constants/Collections";
import { UpdateUserProfile, UserContactPaginationProp } from "types/State";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  getUserProfileDataByCallSign,
  storeUserProfileDataByCallSign,
} from "store/Actions/guest.action";
import { getSelectedGuestUserSelector } from "store/Reducers/guest.reducer";
import { setLoadingStatusForGuest } from "store/Actions/loading.action";
import { fetchSubscriptionStatus } from "store/Actions/subscription.actions";
import useConfig from "./config.hook";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { globalNavigate } from "routes/GlobalRoutes";
import { SETTING_ROUTE } from "constants/Links";
import AnalyticsService from "services/analytics.service";
import { USER_SIGNUP } from "constants/AnalyticsEvents";
import {
  getAllUserContacts,
  getAllUserContactsWithPagination,
  getPaginatedUserContactsWithPagination,
  handleEditLogBook,
} from "store/Actions/logBook.actions";
import CustomValidation from "helpers/Validation";
import { allErrorHandler } from "helpers/ErrorHandler";
import useAuth from "./auth.hook";
import { useDebouncedCallback } from "use-debounce";
import { MembershipStatus, SubscriptionStatus } from "constants/User";
import { usStatesCoordinates } from "constants/Config";
import {
  createSearchIndex,
  getStateLongName,
  getStateShortCode,
} from "helpers/Utils";
import { callSignAssistantModal } from "store/Actions/authorization.actions";
import ProfileService from "services/profile.service";

const useProfile = () => {
  const { handlePrimaryModal } = useConfig();
  const { discourseAuth } = useAuth();

  const dispatch = useDispatch();
  const location = useLocation();
  let { callSignFromParam } = useParams();
  const [searchParams] = useSearchParams();

  if (callSignFromParam) {
    callSignFromParam = callSignFromParam.toUpperCase();
  }
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);
  const isGettingLocationIsLoading: boolean = useSelector(
    getisGettingLocationSelector
  );

  const userAvatar = useSelector(getUserAvatarSelector);

  const userAwards = useSelector(getUserAwardsSelector);
  const userAwardsLoading = useSelector(getIsUserAwardLoadingSelector);
  const [featuredAwards, setFeaturedAwards] = useState<UserAwards[]>([]);

  const guestUserProfile: UserProfile | null = useSelector(
    getSelectedGuestUserSelector
  );
  const [userData, setUserData] = useState<UserProfile | null>(null);
  const isProfileLoading = useSelector(getIsProfileLoadingSelector);
  const isFetching = useSelector(getIsFetchingGuestUserProfileSelector);
  const isFetchingCallSignAvailability = useSelector(
    getIsFetchingCallSignAvailabilitySelector
  );
  const isCallSignAvailable = useSelector(getIsCallSignAvailableSelector);
  const [firstName, setFirstName] = useState<string>(
    userProfile?.firstName || ""
  );
  const [lastName, setLastName] = useState<string>(userProfile?.lastName || "");
  const [callSign, setCallSign] = useState<string>(userProfile?.callSign || "");
  const [country, setCountry] = useState<string>(userProfile?.country || "");
  const [state, setState] = useState<string>(userProfile?.state || "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = useState<string>(userProfile?.email || "");

  const [selectedIndex, setSelectedIndex] = useState<string | null>("");

  const [profilePic, SetProfilePic] = useState<string>(
    userProfile?.profilePic || ""
  );
  const [facebook, setFacebook] = useState<string>(userProfile?.facebook || "");
  const [linkedin, setLinkedin] = useState<string>(userProfile?.linkedin || "");
  const [twitter, setTwitter] = useState<string>(userProfile?.twitter || "");
  const [instagram, setInstagram] = useState<string>(
    userProfile?.instagram || ""
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    userProfile?.phoneNumber || ""
  );
  const [bio, setBio] = useState<string>(userProfile?.bio || "");
  const [longBio, setLongBio] = useState<string>(userProfile?.longBio || "");
  const [locationDenied, setLocationDenied] = useState<boolean>(false);

  const [userName, setUserName] = useState<string>(userProfile?.bio || "");

  const [countryInfo, setCountryInfo] = useState<DXCCList | null>();
  const [stateInfo, setStateInfo] = useState<string>();

  const [memberStatus, setMemberStatus] = useState<string>("");
  const [guestMemberStatus, setGuestMemberStatus] = useState<string>("");

  const [selectedAward, setSelectedAward] = useState<UserAwards | null>(
    userAwards[0] ? userAwards[0] : null
  );

  const handleUserProfile = () => {
    if (auth.currentUser && auth.currentUser.uid) {
      dispatch(checkUserProfile(auth.currentUser.uid));
      dispatch(fetchSubscriptionStatus());
    }
  };

  const [doesntHaveCallSign, setDoesntHaveCallSign] = useState(false);

  useEffect(() => {
    if (userData?.uid) {
      dispatch(fetchUserAwards(userData.uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (userAwards[0]) {
      setSelectedAward(userAwards[0]);
    } else {
      setSelectedAward(null);
    }
  }, [userAwards]);

  useEffect(() => {
    if (userProfile?.membershipStatus) {
      if (userProfile?.membershipStatus === "basic") {
        setMemberStatus("MEMBER");
      } else {
        setMemberStatus(userProfile?.membershipStatus.toLocaleUpperCase());
      }
    } else {
      setMemberStatus("FREE");
    }
  }, [userProfile]);

  const debouncedSetUserProfile = useDebouncedCallback((doc, userProfile) => {
    const userProfileData: UserProfile = doc.data() as UserProfile;
    // console.log("USER PROFILE RERENDER");
    dispatch(storeLoggedInUser(userProfileData));
    if (userProfile.callSign?.trim() === callSignFromParam) {
      setUserData(userProfileData);
    }
  }, 5000);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (auth.currentUser) {
      if (userProfile && userProfile.uid) {
        unsubscribe = onSnapshot(
          doc(db, UserDataTable, userProfile.uid),
          (doc) => {
            debouncedSetUserProfile(doc, userProfile);
          }
        );
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditUser = () => {
    const exludesSpecialCharacters =
      CustomValidation.validateSpecialCharacters(callSign);
    let updatedInfo: UserProfile = {
      bio: bio ? bio : "",
      phoneNumber: phoneNumber ? phoneNumber : "",
      callSign: callSign ? callSign.trim() : "",
      needsLocationOnboarding: true,
      countryCode: countryInfo?.countryCode ? countryInfo.countryCode : "",
      continent: countryInfo?.continent ? countryInfo.continent : "",
      flagCode: countryInfo?.flagCode ? countryInfo.flagCode : "",
      dxccNumber: countryInfo?.dxccNumber ? countryInfo.dxccNumber : 0,
    };

    if (firstName) {
      updatedInfo.firstName = firstName;
    }
    if (lastName) {
      updatedInfo.lastName = lastName;
    }

    if (locationDenied && countryInfo?.country) {
      updatedInfo.country = countryInfo.country || "";
    }
    if (locationDenied && stateInfo) {
      updatedInfo.state = getStateShortCode(stateInfo) || "";
      updatedInfo.stateLongName = getStateLongName(stateInfo);
    }
    if (
      bio &&
      callSign &&
      isChecked &&
      (!locationDenied || (locationDenied && stateInfo && countryInfo))
    ) {
      if (isCallSignAvailable?.available && exludesSpecialCharacters) {
        discourseAuth(auth.currentUser, searchParams);
        const callsignSearchIndex = callSign
          ? createSearchIndex(callSign?.toLowerCase())
          : [];

        if (callsignSearchIndex.length > 0) {
          updatedInfo.callsignSearchIndex = callsignSearchIndex;
        }

        dispatch(editUserProfile(updatedInfo));

        AnalyticsService.logGtmEvent({
          event: USER_SIGNUP,
          method: auth.currentUser?.providerData[0].providerId
            ? auth.currentUser?.providerData[0].providerId
            : "",
          first_name: firstName || location?.state?.firstName,
          last_name: lastName || location?.state?.lastName,
          country: countryInfo,
          userId: auth.currentUser?.uid ? auth.currentUser?.uid : "",
          email: auth.currentUser?.email || "",
        });

        globalNavigate(`/register/location-settings`);
      } else if (!exludesSpecialCharacters) {
        allErrorHandler("special words not allowed");
      } else if (!isCallSignAvailable?.available) {
        dispatch(
          callSignAssistantModal({
            data: isCallSignAvailable?.data || null,
            mode: "registration",
          })
        );
        // showToast({
        //   message:
        //     "An account with this callsign already exists! Please use another callsign, or if you are still having issues reach out to support@worldradioleague.com",
        //   type: ToastTypes.WARN,
        // });
      } else {
        authErrorHandler("Something went wrong!");
      }
    } else if (!callSign && doesntHaveCallSign) {
      authErrorHandler({ code: "Username is Required" });
    } else if (!callSign) {
      authErrorHandler({ code: "Callsign is Required" });
    } else if (!bio) {
      authErrorHandler({ code: "Bio is Required" });
    } else if (locationDenied && !countryInfo) {
      authErrorHandler({ code: "Country is Required" });
    } else if (locationDenied && !stateInfo) {
      authErrorHandler({ code: "State is Required" });
    } else if (
      !bio ||
      !callSign ||
      (locationDenied && !countryInfo) ||
      (locationDenied && !stateInfo)
    ) {
      authErrorHandler({ code: "Empty Fields" });
    } else if (!isChecked) {
      authErrorHandler({ code: "Check Checkbox" });
    }
  };

  const getCoordinates = async () => {
    const coor: CoordinatesParams = await fetchCoordinates();
    if (coor) {
      dispatch(saveCoordinates(coor));
      setLocationDenied(false);
    } else {
      setLocationDenied(true);
    }
  };

  const getCoordinatesListener = async () => {
    // if (userProfile && !userProfile?.gridSquare) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                let coordinates: CoordinatesParams = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                };
                if (coordinates) {
                  dispatch(saveCoordinates(coordinates));
                }
              },
              (e) => {}
            );
          }
        };
      });
    // }
  };

  const handleUpdateUserData = (data: UpdateUserProfile) => {
    if (!data?.user?.firstName?.trim()) {
      authErrorHandler({ code: "First Name is required" });
      return;
    }

    if (!data?.user?.lastName?.trim()) {
      authErrorHandler({ code: "Last Name is required" });
      return;
    }

    if (!data?.user?.callSign) {
      authErrorHandler({ code: "Callsign is Required" });
      return;
    }

    if (!data?.user?.bio?.trim()) {
      authErrorHandler({ code: "Bio is Required" });
      return;
    }

    if (!data?.user?.email?.trim()) {
      authErrorHandler({ code: "Email is required" });
      return;
    }

    dispatch(updateUserProfile(data));
  };

  const getAllUserContactsData = (uid: string) => {
    dispatch(getAllUserContacts(uid));
  };

  const getAllUserContactsDataWithPagination = (uid: string) => {
    dispatch(getAllUserContactsWithPagination(uid));
  };

  const getPaginatedUserContactsDataWithPagination = (
    conditions: UserContactPaginationProp,
    uid?: string
  ) => {
    dispatch(getPaginatedUserContactsWithPagination(conditions, uid));
  };

  const handleFetchDataByCallSign = useCallback(() => {
    dispatch(storeUserProfileDataByCallSign(null));
    const uid = userProfile?.uid ? userProfile?.uid : "";
    
    // If no callSignFromParam or it's empty, load current user's profile
    if (!callSignFromParam || callSignFromParam.trim() === '') {
      if (userProfile) {
        setUserData(userProfile);
        dispatch(setLoadingStatusForGuest(false));
        getAllUserContactsDataWithPagination(uid);
      }
      return;
    }

    // Handle case when viewing own profile with callSign
    if (userProfile?.callSign === callSignFromParam) {
      getAllUserContactsDataWithPagination(uid);
      setUserData(userProfile);
      dispatch(setLoadingStatusForGuest(false));
    } 
    // Handle case when viewing profile with state
    else if (location?.state?.uid) {
      setUserData(location?.state);
      dispatch(setLoadingStatusForGuest(false));
    } 
    // Handle case when viewing other user's profile
    else {
      dispatch(
        getUserProfileDataByCallSign(callSignFromParam, location?.state)
      );
    }
  }, [callSignFromParam, userProfile]);

  const checkCallSignAvailabilityForAuth = (callSign: string) => {
    dispatch(checkCallSignAvailability(callSign));
  };

  const subButtonClick = () => {
    if (memberStatus === "FREE") {
      globalNavigate(SETTING_ROUTE);
    }
  };

  const setFeaturedStatusForAward = (selectedAward: UserAwards) => {
    dispatch(setFeaturedUserAward(selectedAward));
  };

  //fetches call sign profile data by call sign
  const fetchCallSignProfileData = (callSign: string) => {
    dispatch(getUserProfileDataByCallSign(callSign, ""));
  };

  const setUserAvatar = (imageSrc: string) => {
    dispatch(setUserAvatarAction(imageSrc));
  };
  const getIsCurrentUserPaid = () => {
    if (
      (userProfile?.membershipStatus === MembershipStatus.BASIC ||
        userProfile?.membershipStatus === MembershipStatus.PREMIUM ||
        userProfile?.membershipStatus === MembershipStatus.VIP) &&
      userProfile.subscriptionStatus === SubscriptionStatus.ACTIVE
    ) {
      return true;
    }

    return false;
  };

  const getIsGuestUserPaid = () => {
    if (
      (guestUserProfile?.membershipStatus === MembershipStatus.BASIC ||
        guestUserProfile?.membershipStatus === MembershipStatus.PREMIUM ||
        guestUserProfile?.membershipStatus === MembershipStatus.VIP) &&
      guestUserProfile.subscriptionStatus === SubscriptionStatus.ACTIVE
    ) {
      return true;
    }

    return false;
  };

  const setGuestUserProfile = (profile: UserProfile | null) => {
    dispatch(storeUserProfileDataByCallSign(profile));
  };

  const handleUpdateAnalyticsQuota = async (userId: string) => {
    const userProfile = await ProfileService.getUserProfileData(userId);
    if (!userProfile) return;

    let quota = userProfile.quota || {
      analyticsViewsRemaining: 6,
      lastResetDate: new Date().toISOString(),
      isLocked: false
    };

    // Check for monthly reset
    const lastReset = new Date(quota.lastResetDate);
    const now = new Date();
    if (now.getMonth() !== lastReset.getMonth() || now.getFullYear() !== lastReset.getFullYear()) {
      quota = {
        analyticsViewsRemaining: 6,
        lastResetDate: now.toISOString(),
        isLocked: false
      };
    }

    // Decrement views if not locked
    if (!quota.isLocked && quota.analyticsViewsRemaining > 0) {
      quota.analyticsViewsRemaining -= 1;
      quota.isLocked = quota.analyticsViewsRemaining === 0;
    }
    userProfile.quota = quota;
    // Add isQuotaUpdate flag
    dispatch(updateUserProfile({
      user: userProfile,
      isQuotaUpdate: true
    }));
  };

  return {
    handleFetchDataByCallSign,
    handleUpdateUserData,
    handleEditUser,
    isProfileLoading,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    callSign,
    setCallSign,
    country,
    phoneNumber,
    setPhoneNumber,
    bio,
    setBio,
    longBio,
    setLongBio,
    isChecked,
    setIsChecked,
    handleUserProfile,
    userProfile,
    userData,
    setUserData,
    callSignFromParam,
    facebook,
    linkedin,
    twitter,
    instagram,
    setCountry,
    setFacebook,
    setLinkedin,
    setTwitter,
    setInstagram,
    getCoordinates,
    getCoordinatesListener,
    locationDenied,
    countryInfo,
    setCountryInfo,
    stateInfo,
    setStateInfo,
    state,
    setState,
    profilePic,
    SetProfilePic,
    isFetching,
    guestUserProfile,
    setSelectedIndex,
    selectedIndex,
    userAwards,
    userAwardsLoading,
    setSelectedAward,
    selectedAward,
    doesntHaveCallSign,
    setDoesntHaveCallSign,
    checkCallSignAvailabilityForAuth,
    isFetchingCallSignAvailability,
    isCallSignAvailable,
    memberStatus,
    userName,
    setUserName,
    subButtonClick,
    setGuestMemberStatus,
    guestMemberStatus,
    location,
    setFeaturedStatusForAward,
    featuredAwards,
    setFeaturedAwards,
    isGettingLocationIsLoading,
    getPaginatedUserContactsDataWithPagination,
    getAllUserContactsData,
    email,
    fetchCallSignProfileData,
    userAvatar,
    setUserAvatar,
    getIsGuestUserPaid,
    getIsCurrentUserPaid,
    setGuestUserProfile,
    handleUpdateAnalyticsQuota,
  };
};

export default useProfile;
