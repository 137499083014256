import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { globalNavigate } from "routes/GlobalRoutes";

export const ImportAdiFile = () => {
  return (
    <BorderButtonSecondary
      text={`Import ADIF`}
      onClick={() => globalNavigate("/log-contacts/import-contact")}
      className="sm:mb-0"
      icon={
        <img
          className="h-5 w-5 mr-2 filter invert dark:filter-none dark:invert-0"
          alt="import adif"
          src={Icons.ImportFile}
        />
      }
    />
  );
};
