import { createReducer } from '@reduxjs/toolkit';
import { AnalyticsState, AnalyticsResult, CombinedAnalyticsResponse, AnalyticsFilters } from 'types/Analytics.types';
import {
  fetchAnalytics,
  fetchAnalyticsSuccess,
  fetchAnalyticsFailure,
  clearAnalytics,
} from '../Actions/analytics.actions';

const initialState: AnalyticsState = {
  loading: false,
  error: null,
  dxData: null,
  statesData: null,
  lastFetchedFilters: null,
};

export const analyticsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAnalytics, (state, action) => {
      state.loading = true;
      state.error = null;
      // Store the filters from the request payload
      state.lastFetchedFilters = action.payload.filters || null;
    })
    .addCase(fetchAnalyticsSuccess, (state, action) => {
      state.loading = false;
      const payload = action.payload as AnalyticsResult;
      
      // Check if it's a combined response
      if ('dx' in payload || 'states' in payload) {
        const combinedPayload = payload as CombinedAnalyticsResponse;
        if (combinedPayload.dx) {
          state.dxData = combinedPayload.dx;
        }
        if (combinedPayload.states) {
          state.statesData = combinedPayload.states;
        }
      } else {
        // Handle single type response
        if ('countryList' in payload) {
          state.dxData = payload;
        } else {
          state.statesData = payload;
        }
      }
    })
    .addCase(fetchAnalyticsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(clearAnalytics, (state) => {
      state.dxData = null;
      state.statesData = null;
      state.lastFetchedFilters = null;
    });
});

// Selectors
export const getAnalyticsLoadingSelector = (state: any) => state.analytics.loading;
export const getAnalyticsErrorSelector = (state: any) => state.analytics.error;
export const getDxAnalyticsSelector = (state: any) => state.analytics.dxData;
export const getStatesAnalyticsSelector = (state: any) => state.analytics.statesData;
export const getLastFetchedFiltersSelector = (state: any) => state.analytics.lastFetchedFilters;