import RouteLoading from "components/Loading/RouteLoading";
import useAuth from "hooks/auth.hook";
import useProfile from "hooks/profile.hook";
import { useEffect } from "react";
import AuthRoute from "routes/AuthRoute";
import RootRoute from "routes/RootRoute";
import { globalNavigate } from "./GlobalRoutes";
import { useSelector } from "react-redux";
import { getDesktopLoadingSelector } from "store/Reducers/loading.reducer";

function AppRoute() {
  const { userProfile } = useProfile();
  const { isDiscourseLoading } = useAuth();
  const isDesktopLoading = useSelector(getDesktopLoadingSelector);
  // if (userProfile === false) {
  //   return <RouteLoading />;
  // }

  const isAuthenticated =
    userProfile && !userProfile?.needsLocationOnboarding ? true : false;

  useEffect(() => {
    if (userProfile?.needsLocationOnboarding) {
      globalNavigate(`/register/location-settings`);
    }
  }, [useProfile]);

  return (
    <>
      {isAuthenticated ? <RootRoute /> : <AuthRoute />}
      {isDiscourseLoading || isDesktopLoading ? (
        <div className="absolute z-10 top-0 w-screen h-screen">
          <RouteLoading
            text={
              isDesktopLoading
                ? "Redirecting to WRL Cat Control..."
                : "Redirecting to Community..."
            }
          />
        </div>
      ) : null}
    </>
  );
}

export default AppRoute;
