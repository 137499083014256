import Icons from "assets/Icons";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";

import React, { useState, useEffect } from "react";
import { RiFacebookFill } from "react-icons/ri";
import { AwardListProps } from "types/Component";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { savePDFAward, sortAwardsByType } from "helpers/Utils";
import moment from "moment";
import useSubscription from "hooks/subscription.hook";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddAwardModal } from "store/Actions/profile.actions";
import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";
import { getAuth } from "firebase/auth";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import AwardService from "services/award.service";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { motion } from "framer-motion";
import { Award } from "types/Models";
import { globalNavigate } from "routes/GlobalRoutes";

function AwardList({
  userAwards,
  selectedAward,
  setSelectedAward,
  setFeaturedStatusForAward,
  guestUser,
  guestMemberStatus,
  userCallSign,
  user,
}: AwardListProps) {
  const { isUserPremiumAndAbove, navigateToPricing } = useSubscription();
  const dispatch = useDispatch();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const userProfile = useSelector(getUserProfileSelector);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationProgress, setCalculationProgress] = useState<string | null>(null);
  const [localAwards, setLocalAwards] = useState<Award[]>(userAwards);
  const isFreeUser = !userProfile?.membershipStatus || userProfile?.membershipStatus === "free"; 

  useEffect(() => {
    if(!guestUser) {
      handleCalculateAwardsInBackground();
    }
  }, []);

  const handleCalculateAwardsInBackground = async () => {
    if (!currentUser?.uid || isCalculating) return;

    setIsCalculating(true);
    setCalculationProgress("Checking for new awards...");

    try {
      const result = await AwardService.calculateUserAwards({
        userId: currentUser.uid,
        userCountry: userProfile?.country || "",
      });

      const uniqueAwards = result.filter(
        (award: Award) =>
          !localAwards.some(
            (localAward: Award) => localAward.awardId === award.awardId
          )
      );
      if (uniqueAwards?.length > 0) {
        setLocalAwards(sortAwardsByType([...localAwards, ...uniqueAwards]));
        setCalculationProgress(
          `🎉 Congratulations! You've earned ${uniqueAwards.length} new award(s)!`
        );
      } else {
        setCalculationProgress("✓ Your awards are up to date!");
      }
    } catch (error) {
      setCalculationProgress(
        "Unable to check for new awards. Your existing awards are still visible."
      );
    } finally {
      setTimeout(() => {
        setCalculationProgress(null);
        setIsCalculating(false);
      }, 5000);
    }
  };

  let displayAwards = [];
  if(guestUser) {
    displayAwards = guestMemberStatus === 'FREE' ? localAwards.filter((award) => award.type !== 'DX' && award.type !== 'STATES') : localAwards;
  }
  else{
     // Show first 3 awards for free users, but show all for paid users (non-guest users)
    displayAwards = isFreeUser ? localAwards.slice(0, 3) : localAwards;
  }

  return (
    <div className="dark:bg-[#0B0B39] bg-white mt-12 w-full rounded-[20px] sm:rounded-[25px] md:rounded-[50px] flex flex-col sm:flex-row justify-between">
      {selectedAward && displayAwards.length > 0 ? (
        <div className="w-full sm:w-[45%] h-full">
          <div className="relative flex flex-col items-center h-full">
            <div className="absolute w-full h-[110%] absoluteGradientImg top-[-5%] flex justify-center">
              <img
                className="w-full object-fill"
                src={Icons.GradientRectanglePic}
                alt="bg"
              />
            </div>
            <div className="dark:bg-[#161638] bg-white shadow-lg h-full w-full rounded-[24px] flex flex-col items-center pt-8 lg:pt-12 pb-6 relative">
              <img
                className="w-[80%]"
                src={selectedAward.image}
                alt="profile pic"
              />
              {guestUser || isFreeUser ? null : (
                <div className="w-full xl:w-[300px] px-2 md:px-4 mt-3 flex justify-between">
                  <div className="w-[47%]">
                    <BorderButtonPrimary
                      className="py-[1.5px]"
                      height="h-fit"
                      text="Save PDF"
                      onClick={() => {
                        isUserPremiumAndAbove()
                          ? savePDFAward(
                              moment
                                .utc(
                                  new Date(
                                    selectedAward?.timestamp?.seconds
                                      ? selectedAward?.timestamp?.seconds * 1000
                                      : new Date().getTime()
                                  )
                                )
                                .format("MMM, DD YYYY"),
                              `${user?.firstName} ${user?.lastName}`,
                              user?.callSign || "",
                              `${user?.foundingMemberCount}`,
                              true,
                              selectedAward
                            )
                          : navigateToPricing();
                      }}
                      childClassName="text-sm md:text-base py-2 px-[10px]"
                    />
                  </div>
                  <div className="w-[47%]">
                    <BorderButtonPrimary
                      className="py-[1.5px]"
                      height="h-fit"
                      text="Print"
                      onClick={() => {
                        isUserPremiumAndAbove()
                          ? savePDFAward(
                              moment
                                .utc(
                                  new Date(
                                    selectedAward?.timestamp?.seconds
                                      ? selectedAward?.timestamp?.seconds * 1000
                                      : new Date().getTime()
                                  )
                                )
                                .format("MMM, DD YYYY"),
                              `${user?.firstName} ${user?.lastName}`,
                              user?.callSign || "",
                              `${user?.foundingMemberCount}`,
                              false,
                              selectedAward
                            )
                          : navigateToPricing();
                      }}
                      childClassName="text-sm md:text-base py-2 px-[10px]"
                    />
                  </div>
                </div>
              )}

              {guestUser ||
              (isFreeUser &&
                (selectedAward.type === "DX" ||
                  selectedAward.type === "STATES")) ? null : (
                <div className="w-full xl:w-[300px] px-2 md:px-4 mt-3 sm:mt-1">
                  <BorderButtonPrimary
                    className="py-[1.5px]"
                    height="h-fit"
                    text={
                      selectedAward?.featured
                        ? "Remove Featured Award from Profile"
                        : "Set as Featured Award on Profile"
                    }
                    onClick={() => setFeaturedStatusForAward(selectedAward)}
                    childClassName="text-sm md:text-base py-2 px-[10px]"
                  />
                </div>
              )}
              {guestUser ? null : isFreeUser ? (
                <>
                  {selectedAward.type === "DX" ||
                  selectedAward.type === "STATES" ? (
                    // Case 01: Free user with DX/STATES award - Show all premium features
                    <div className="flex items-center justify-center">
                      {/* Premium features teaser - All features */}
                      <div className=" inset-0 flex flex-col items-center justify-center">
                        <div className="bg-gradient-to-r from-purple-600 to-blue-500 p-1 rounded-lg">
                          <div className="dark:bg-[#0B0B39] bg-white px-6 py-4 rounded-lg">
                            <p className="text-yellow-400 font-bold text-lg mb-2">
                            🏆 Unlock Premium Features
                            </p>
                            <div className="dark:text-white text-[#2b2e48] text-sm space-y-2 mb-3">
                              <p>• Download PDF certificates</p>
                              <p>• Print your achievements</p>
                              <p>• Feature awards on your profile</p>
                              <p>• Share with your community</p>
                            </div>
                            <BorderButtonSecondary
                              text="Upgrade to Premium"
                              onClick={() => globalNavigate("/membership")}
                              className="animate-pulse hover:animate-none"
                              childClassName="py-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // Case 02: Free user with regular award - Show only PDF and Print premium features
                    <>
                      {/* Premium features teaser - PDF and Print only */}
                      <div className="flex flex-col items-center justify-center">
                        <div className="bg-gradient-to-r from-purple-600 to-blue-500 p-1 rounded-lg">
                          <div className="dark:bg-[#0B0B39] bg-white px-6 py-4 rounded-lg">
                            <p className="text-yellow-400 font-bold text-lg mb-2">
                            🏆 Unlock Premium Features
                            </p>
                            <div className="dark:text-white text-[#2b2e48] text-sm space-y-2 mb-3">
                              <p>• Download PDF certificates</p>
                              <p>• Print your achievements</p>
                            </div>
                            <BorderButtonSecondary
                              text="Upgrade to Premium"
                              onClick={() => globalNavigate("/membership")}
                              className="animate-pulse hover:animate-none"
                              childClassName="py-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <p className="dark:text-white text-[#2b2e48] font-[Barlow] text-3xl text-center font-bold">
                          Share to socials
                        </p>
                        <div className="flex items-center justify-center mt-2">
                          <FacebookShareButton
                            url={`${window.location.origin}/profile/${userCallSign}`}
                            title={`I just won ${selectedAward?.name} from the World Radio League!`}
                            className="Demo__some-network__share-button"
                            quote={`I just won ${selectedAward?.name} from the World Radio League!`}
                            hashtag="#WorldRadioLeague"
                          >
                            <RiFacebookFill
                              color="#fff"
                              className="mx-3 w-4 h-4 lg:w-5 lg:h-5 invert dark:filter-none dark:invert-0"
                            />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={`${window.location.origin}/profile/${userCallSign}`}
                            title={`I just won ${selectedAward?.name} from the World Radio League!`}
                          >
                            <img
                              className="mx-3 w-4 h-4 lg:w-5 lg:h-5 invert dark:filter-none dark:invert-0"
                              src={Icons.TwitterXWhite}
                              alt="twitter icon"
                            />
                          </TwitterShareButton>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                // Premium user - Show all features
                <div>
                  <p className="dark:text-white text-[#2b2e48] font-[Barlow] text-3xl font-bold">
                    Share to socials
                  </p>
                  <div className="flex items-center justify-center mt-2">
                    <FacebookShareButton
                      url={`${window.location.origin}/profile/${userCallSign}`}
                      title={`I just won ${selectedAward?.name} from the World Radio League!`}
                      className="Demo__some-network__share-button"
                      quote={`I just won ${selectedAward?.name} from the World Radio League!`}
                      hashtag="#WorldRadioLeague"
                    >
                      <RiFacebookFill
                        color="#fff"
                        className="mx-3 w-4 h-4 lg:w-5 lg:h-5 invert dark:filter-none dark:invert-0"
                      />
                    </FacebookShareButton>
                    {/* <div
                    className="cursor-pointer"
                    onClick={() =>
                      openInNewTab(
                        `https://www.linkedin.com/sharing/share-offsite/?url=https://plus.unsplash.com/premium_photo-1666232903265-ba0419aa3ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=800&q=60`
                      )
                    }
                  >
                    <SiLinkedin
                      color="#fff"
                      className="mx-3 w-4 h-4 lg:w-5 lg:h-5"
                    />
                  </div> */}
                    <TwitterShareButton
                      url={`${window.location.origin}/profile/${userCallSign}`}
                      title={`I just won ${selectedAward?.name} from the World Radio League!`}
                      className="Demo__some-network__share-button"
                    >
                      <img
                        className="mx-3 w-4 h-4 lg:w-5 lg:h-5 invert dark:filter-none dark:invert-0"
                        src={Icons.TwitterXWhite}
                        alt="twitter icon"
                      />
                    </TwitterShareButton>
                    {/* <PiInstagramLogoFill
                    color="#fff"
                    className="mx-3 w-4 h-4 lg:w-5 lg:h-5"
                  /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="w-full mt-12 sm:mt-0 sm:w-[50%] flex flex-col py-10 pl-5 pr-5">
        {!guestUser && (
          <div className="w-full flex justify-end pr-3 gap-4 sticky top-0 z-10">
            <div className="w-[220px] mr-4">
              <AccessControl
                permission={PERMISSIONS.addAward}
                children={
                  <BorderButtonSecondary
                    text={`Add an Award`}
                    onClick={() => dispatch(setShowAddAwardModal(user!))}
                    className="sm:mb-0 cursor-pointer"
                  />
                }
              />
            </div>
          </div>
        )}

        <div className="flex flex-col w-full mt-6">
          {calculationProgress && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="w-full text-center mb-4"
            >
              <div
                className={`text-sm ${
                  calculationProgress.includes("Congratulations")
                    ? "text-green-500"
                    : "dark:text-white text-gray-900"
                }`}
              >
                {calculationProgress}
              </div>
              {isCalculating && (
                <div className="mt-2">
                  <ActivityIndicator size={24} />
                </div>
              )}
            </motion.div>
          )}

          <div className="w-full flex flex-wrap grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {displayAwards.map((award, index) => (
              <motion.div
                key={award.id}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="h-fit mx-[3%]"
              >
                <img
                  className="w-full cursor-pointer"
                  src={award.image}
                  alt={award.name}
                  onClick={() => setSelectedAward(award)}
                />
              </motion.div>
            ))}
          </div>
        </div>

        {!guestUser && isFreeUser && localAwards.length > 3 && (
          <div className="mt-8 relative">
            {/* Blurred preview of remaining awards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 opacity-40 blur-[2px] pointer-events-none">
              {localAwards.slice(3, 6).map((award, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 + 0.1 * index }}
                  className="dark:bg-[#161638] bg-gray-100 rounded-xl p-4 relative overflow-hidden"
                >
                  <h3 className="dark:text-white text-gray-900 font-bold mb-1">
                    {award.name}
                  </h3>
                  <p className="dark:text-[#adb9e1] text-gray-600 text-sm mb-2">
                    {award.description}
                  </p>
                </motion.div>
              ))}
            </div>

            {/* Upgrade overlay */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center">
                <p className="dark:text-white text-gray-900 font-bold mb-2">
                  {localAwards.length - 3} More Awards Available
                </p>
                <p className="dark:text-[#adb9e1] text-gray-600 text-sm mb-4">
                  Upgrade to track all your awards and achievements!
                </p>
                <BorderButtonSecondary
                  text="Unlock All Awards"
                  onClick={() => globalNavigate("/membership")}
                  height="h-[40px]"
                  childHeight="h-[37px]"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(AwardList);
