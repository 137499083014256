import React, { FC } from "react";
import { QuickActionItem } from "./QuickActionItem";
import { NewGeneralLogBook } from "./actions/NewGeneral";
import { NewPotaActivation } from "./actions/NewPotaActivation";
import { title } from "process";
import { ImportAdiFile } from "./actions/ImportAdiFile";

type QuickActionBarProps = {
  title: string;
};

export const QuickActionBar: FC<QuickActionBarProps> = ({ title }) => {
  return (
    <div className="flex flex-col items-center sm:flex-row gap-4 p-4 sm:p-6">
      <div className="flex items-center dark:text-white text-[#2b2e48] font-Play">
        {title} :
      </div>
      <QuickActionItem>
        <NewPotaActivation />
      </QuickActionItem>
      <QuickActionItem>
        <NewGeneralLogBook />
      </QuickActionItem>
      <QuickActionItem>
        <ImportAdiFile />
      </QuickActionItem>
    </div>
  );
};
