import { useEffect } from "react";
import "./App.css";
import { auth, generateOwnUserToken } from "firebase-config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoute from "routes";
import useProfile from "hooks/profile.hook";
import { useDispatch, useSelector, batch } from "react-redux";
import {
  fetchUserAwards,
  sendWordPressLogin,
  setImpersonationData,
  setShowAccountDeleteModal,
  setShowAddAwardModal,
  setUserAdminControl,
  SHOW_REAUTHENTICATE_MODAL,
  storeLoggedInUser,
} from "store/Actions/profile.actions";
import useAuth from "hooks/auth.hook";
import { useSearchParams } from "react-router-dom";
import {
  LOADING_APPLE_AUTH,
  LOADING_GOOGLE_AUTH_STATUS,
  setDesktopLoading,
  setDiscourseLoading,
} from "store/Actions/loading.action";
import useNotification from "hooks/notification.hook";
import useLogBook from "hooks/logBook.hook";
import {
  saveDesktopLoginParams,
  saveDiscourseParams,
} from "store/Actions/authorization.actions";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import OneSignal from "react-onesignal";
import {
  setContestIdForEditModel,
  setEnrollDetails,
  setShowAddContestModal,
} from "store/Actions/contest.actions";
import {
  mergeLogBookContacts,
  setAddAntennaModal,
  setAddRadioModal,
  setAddSpotModal,
  setAddStationsModal,
  setConfirmChangesModalData,
} from "store/Actions/logBook.actions";
import { setShowAwardModal as setShowAwardModalAction } from "store/Actions/award.actions";
function App() {
  const { handleUserProfile } = useProfile();
  const { logbookCollectionListner } = useLogBook();
  const { fetchNotifications } = useNotification();
  const [searchParams] = useSearchParams();
  const { discourseAuth } = useAuth();
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);
  const openDesktopApp = async (user: any) => {
    try {
      dispatch(setDesktopLoading(true));

      //get user idToken
      const result: any = await generateOwnUserToken({ userId: user.uid });
      if (result.data && result.data.token) {
        //open desktop app
        dispatch(saveDesktopLoginParams(null));
        dispatch(setDesktopLoading(false));

        window.location.href = `wrl://auth?token=${result.data.token}`;
      }
    } catch (err) {
      dispatch(setDesktopLoading(false));
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && process.env.REACT_APP_ONESIGNAL_KEY) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_KEY || "",
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
      });
    }
  }, []);

  const closeAllModals = () => {
    batch(() => {
      dispatch(setShowAddContestModal(false));
      dispatch(setContestIdForEditModel(null));
      dispatch(setUserAdminControl(null));
      dispatch(setAddSpotModal(false));
      dispatch(setAddRadioModal(false));
      dispatch(setAddAntennaModal(false));
      dispatch(setShowAwardModalAction(false));
      dispatch(setAddStationsModal(false));
      dispatch({
        type: SHOW_REAUTHENTICATE_MODAL,
        payload: false,
      });
      dispatch(setEnrollDetails(null));
      dispatch(setShowAddAwardModal(null));
      dispatch(setShowAccountDeleteModal(false));
      dispatch(mergeLogBookContacts(null));
      dispatch(setConfirmChangesModalData(null));
    });
  };

  useEffect(() => {
    const sso: string | null = searchParams.get("sso");
    const sig: string | null = searchParams.get("sig");
    const wrlDesktop: string | null = searchParams.get("wrlDesktop");

    if (wrlDesktop) {
      dispatch(saveDesktopLoginParams({ wrlDesktop }));
    } else {
      dispatch(saveDesktopLoginParams(null));
    }

    if (sso && sig) {
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/register"
      ) {
        dispatch(setDiscourseLoading(true));
        dispatch(saveDiscourseParams({ sso, sig }));
      } else {
        dispatch(setDiscourseLoading(false));
        dispatch(saveDiscourseParams({ sso, sig }));
      }
    } else {
      dispatch(setDiscourseLoading(false));
      dispatch(saveDiscourseParams(null));
    }
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      if (user) {
        fetchNotifications();
        handleUserProfile();
        dispatch(fetchUserAwards(user.uid));
        dispatch(sendWordPressLogin());

        if (
          userProfile &&
          userProfile.firstName &&
          userProfile.lastName &&
          userProfile.bio &&
          userProfile?.callSign &&
          userProfile?.callSign?.length
        ) {
          //handle CAT Desktop app auth
          const wrlDesktop: string | null = searchParams.get("wrlDesktop");
          if (wrlDesktop) {
            openDesktopApp(user);
          } else {
            discourseAuth(user, searchParams);
          }
        } else {
          dispatch(saveDiscourseParams({ sso, sig }));
        }
      } else {
        dispatch(setImpersonationData(null));
        dispatch(storeLoggedInUser(null));
        dispatch({ type: LOADING_GOOGLE_AUTH_STATUS, payload: false });
        dispatch({ type: LOADING_APPLE_AUTH, payload: false });
      }
    });
    const logBookUnsubscribe = logbookCollectionListner();
    closeAllModals();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (logBookUnsubscribe) {
        logBookUnsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app">
      <AppRoute />
      <ToastContainer />
    </div>
  );
}

export default App;
