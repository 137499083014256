import React from "react";
import Icons from "assets/Icons";

interface DXStatsProps {
  totalWorked: number;
  totalConfirmed: number;
  needConfirmation: number;
  title: string;
  maxValue: number;
  minValue: number;
  milestones?: number[];
}

const DXStats: React.FC<DXStatsProps> = ({
  totalWorked,
  totalConfirmed,
  needConfirmation,
  title,
  maxValue,
  minValue,
  milestones = [1, 10, 50, 100],
}) => {
  // Calculate percentages for progress bars considering minValue
  const notConfirmedPercent = Math.min(
    // ((needConfirmation - minValue) / (maxValue - minValue)) * 100,
    (totalWorked / maxValue) * 100,
    100
  );
  const confirmedPercent = Math.min(
    // ((totalConfirmed - minValue) / (maxValue - minValue)) * 100,
    (totalConfirmed / maxValue) * 100,
    100
  );

  // Generate all numbers for scale including milestones
  const allNumbers = new Set([
    ...Array.from({ length: 11 }, (_, i) =>
      Math.round(minValue + (i / 10) * (maxValue - minValue))
    ),
    ...milestones, // Add milestone numbers to ensure they're included
  ]);

  // Convert to array, sort, and filter out numbers above maxValue
  const scaleNumbers = Array.from(allNumbers)
    .sort((a, b) => a - b)
    .filter((num) => num <= maxValue);

  return (
    <div className="w-full h-[141px] flex-col justify-start items-start gap-3 inline-flex">
      {/* Title */}
      <div className="self-stretch justify-center items-center gap-[3.50px] inline-flex">
        <div className="grow shrink basis-0 dark:text-white text-gray-900 text-lg font-bold font-['Barlow'] leading-tight">
          {title}
        </div>
      </div>

      {/* Progress Bars Container */}
      <div className="self-stretch h-[62px] flex-col justify-start items-start gap-2.5 flex">
        {/* Not Confirmed Progress Bar */}
        <div className="h-4 w-full dark:bg-white/10 bg-gray-200 rounded-[43.81px] justify-start items-center inline-flex relative">
          {notConfirmedPercent > 0 && (
            <div
              className="h-4 bg-[#ff6f46] rounded-[43.81px] relative"
              style={{ width: `${Math.max(notConfirmedPercent, 2)}%` }}
            >
              <div className={`absolute inset-0 flex items-center justify-center text-white text-sm font-bold font-['Barlow']`}>
                {notConfirmedPercent.toFixed(0)}%
              </div>
            </div>
          )}
        </div>

        {/* Scale Lines */}
        <div className="self-stretch justify-between items-start inline-flex h-[2px]">
          {scaleNumbers.map((value) => (
            <div
              key={value}
              className="h-full flex items-center justify-center"
            >
              <div className="h-3 w-[2px] dark:bg-white/20 bg-gray-300" />
            </div>
          ))}
        </div>

        {/* Confirmed Progress Bar Container */}
        <div className="self-stretch flex-col justify-start items-center flex">
          <div className="h-4 w-full dark:bg-white/10 bg-gray-200 rounded-[43.81px] justify-start items-center inline-flex">
            {confirmedPercent > 0 && (
              <div
                className="h-4 relative"
                style={{ width: `${Math.max(confirmedPercent, 2)}%` }}
              >
                <div className="w-full h-4 bg-gradient-to-r from-[#00d395] to-[#17f9da] rounded-[43.81px] relative">
                  <div className={`absolute inset-0 flex items-center justify-center text-[#010a41] text-sm font-bold font-['Barlow']`}>
                    {confirmedPercent.toFixed(0)}%
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Scale Numbers and Trophies */}
          <div className="self-stretch justify-between items-start inline-flex mt-2">
            {scaleNumbers.map((value) => (
              <div key={value} className="flex flex-col items-center">
                {/* Trophy Icon for milestone values */}
                {milestones.includes(value) && (
                  <img
                    src={Icons.TrophyIcon}
                    alt="trophy"
                    className="w-3 h-3 mb-1"
                  />
                )}

                {/* Number */}
                <div className="text-center dark:text-white/90 text-gray-600 text-sm font-medium font-['Barlow'] leading-[14.02px]">
                  {value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Legend */}
      <div className="self-stretch justify-start items-center gap-2.5 inline-flex mt-8">
        <div className="justify-start items-center gap-[5px] flex">
          <div className="w-2 h-2 rounded-full bg-[#17f9da]" />
          <div className="dark:text-white/90 text-gray-700 text-sm font-medium font-['Barlow'] leading-[14.02px]">
            Confirmed
          </div>
          <div className="dark:text-white/70 text-gray-500 text-sm font-medium font-['Barlow'] leading-[14.02px]">
            {totalConfirmed}
          </div>
        </div>
        <div className="justify-start items-center gap-[5px] flex ml-2.5">
          <div className="w-2 h-2 rounded-full bg-[#ff6f46]" />
          <div className="dark:text-white/90 text-gray-700 text-sm font-medium font-['Barlow'] leading-[14.02px]">
            Not Confirmed
          </div>
          <div className="dark:text-white/70 text-gray-500 text-sm font-medium font-['Barlow'] leading-[14.02px]">
            {needConfirmation}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DXStats;
