export const LOADING_AUTH_STATUS = "LOADING_AUTH_STATUS";
export const LOADING_GOOGLE_AUTH_STATUS = "LOADING_GOOGLE_AUTH_STATUS";
export const LOADING_APPLE_AUTH = "LOADING_APPLE_AUTH";

export const LOADING_FACEBOOK_AUTH_STATUS = "LOADING_FACEBOOK_AUTH_STATUS";
export const DISCOURSE_LOADING = "DISCOURSE_LOADING";
export const LOADING_LOGBOOK_STATUS = "LOADING_LOGBOOK_STATUS";
export const LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS =
  "LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS";
export const LOADING_LOGBOOK_SAVEING_STATUS = "LOADING_LOGBOOK_SAVEING_STATUS";
export const LOADING_PROFILE_STATUS = "LOADING_PROFILE_STATUS";
export const LOADING_LEADERBOARD_STATUS = "LOADING_LEADERBOARD_STATUS";
export const LOADING_LEADERBOARD_STATUS_BY_MODE =
  "LOADING_LEADERBOARD_STATUS_BY_MODE";
export const SUBSCRIPTION_LOADING = "SUBSCRIPTION_LOADING";
export const LOADING_GUEST_USER_PROFILE_STATUS =
  "LOADING_GUEST_USER_PROFILE_STATUS";
export const FETCH_AWARD_LOADING = "FETCH_AWARD_LOADING";
export const LOADING_CALLSIGN_AVAILABILITY_STATUS =
  "LOADING_CALLSIGN_AVAILABILITY_STATUS";
export const LOADING_STATUS_FOR_GETTING_LOCATION =
  "LOADING_STATUS_FOR_GETTING_LOCATION";

export const LOADING_CALLSIGN_DATA = "LOADING_CALLSIGN_DATA";
export const LOADING_UPLOAD_STATUS = "LOADING_UPLOAD_STATUS";

export const FEATURED_MEMBERS_LOADING = "FEATURED_MEMBERS_LOADING";

export const ACTIVE_CONTEST_LOADING = "ACTIVE_CONTEST_LOADING";
export const UPCOMING_CONTEST_LOADING = "UPCOMING_CONTEST_LOADING";
export const PASSED_CONTEST_LOADING = "PASSED_CONTEST_LOADING";

export const ENROLL_CONTEST_LOADING = "ENROLL_CONTEST_LOADING";

export const CONTESTANT_DETAIL_LOADING = "CONTESTANT_DETAIL_LOADING";

export const CONTEST_DETAIL_LOADING = "CONTEST_DETAIL_LOADING";

export const CONTEST_LEADER_LOADING = "CONTEST_LEADER_LOADING";

export const CONTEST_LEADER_BOARD_LOADING = "CONTEST_LEADER_BOARD_LOADING";
export const CONTEST_CONTACTS_LOADING = "CONTEST_CONTACTS_LOADING";

export const LEADER_BOARD_BY_WEEK = "LEADER_BOARD_BY_WEEK";
export const LEADER_BOARD_MODE_LOADING = "LEADER_BOARD_MODE_LOADING";

export const CONTESTANT_PROFILE_DETAIL_LOADING =
  "CONTESTANT_PROFILE_DETAIL_LOADING";
export const MAP_MEMBERS_LOADING = "MAP_MEMBERS_LOADING";
export const GLOBE_MEMBERS_LOADING = "GLOBE_MEMBERS_LOADING";
export const LOADING_DISTANCE_LEADERBOARD = "LOADING_DISTANCE_LEADERBOARD";
export const LOADING_STATE_LEADER_BOARD = "LOADING_STATE_LEADER_BOARD";

export const LOADING_DX_LEADERBOARD = "LOADING_DX_LEADERBOARD";

export const ADI_UPLOAD_LOADING = "ADI_UPLOAD_LOADING";

export const ADMIN_CONTROL_LOADING = "ADMIN_CONTROL_LOADING";
export const DAILY_LEADER_LOADING = "DAILY_LEADER_LOADING";

export const SPOT_LOADING = "SPOT_LOADING";
export const LOGBOOK_SETTING_LOADING = "LOGBOOK_SETTING_LOADING";

export const ACTIVITIES_MAP_LOADING = "ACTIVITIES_MAP_LOADING";
export const DESKTOP_LOADING = "DESKTOP_LOADING";

export const setLoadingStatusForGuest = (payload: boolean) => {
  return {
    type: LOADING_GUEST_USER_PROFILE_STATUS,
    payload: payload,
  };
};

export const setLoadingImageUploadStatus = (payload: boolean) => {
  return {
    type: LOADING_UPLOAD_STATUS,
    payload: payload,
  };
};

export const setDiscourseLoading = (payload: boolean) => {
  return {
    type: DISCOURSE_LOADING,
    payload: payload,
  };
};

export const setDesktopLoading = (payload: boolean) => {
  return {
    type: DESKTOP_LOADING,
    payload: payload,
  };
};
