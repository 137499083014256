import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import LogBookService from 'services/logBook.service';
import {
  FETCH_ANALYTICS,
  fetchAnalyticsSuccess,
  fetchAnalyticsFailure,
} from '../Actions/analytics.actions';
import { AnalyticsFilters, AnalyticsResult } from 'types/Analytics.types';

interface FetchAnalyticsPayload {
  userId: string;
  userCountry: string;
  type: "dx" | "us-states";
  filters?: AnalyticsFilters;
  calculateAwards: boolean;
}

function* fetchAnalyticsSaga(action: PayloadAction<FetchAnalyticsPayload>) {
  try {
    const { userId, userCountry, type, filters, calculateAwards } = action.payload;
    
    const analyticsType = type === "dx" ? "DX" : "STATES";
    const analyticsFilters = {
      ...filters,
      mode: filters?.mode === "ALL" ? undefined : filters?.mode,
      band: filters?.bands?.length === 1 ? filters.bands[0] : undefined
    };

    const response: AnalyticsResult = yield call(LogBookService.getAnalytics, {
      userId,
      userCountry,
      type: analyticsType,
      filters: analyticsFilters,
      calculateAwards
    });

    yield put(fetchAnalyticsSuccess(response));
  } catch (error) {
    yield put(fetchAnalyticsFailure(error instanceof Error ? error.message : 'Failed to fetch analytics'));
  }
}

export function* watchAnalytics() {
  yield takeLatest(FETCH_ANALYTICS, fetchAnalyticsSaga);
} 