import React from "react";

type ModeCategoryCounts = {
  Phone: number;
  CW: number;
  Digital: number;
  Other: number;
};

type Props = {
  qsoCounts: ModeCategoryCounts; // Number of QSOs per category
  pointsCounts: ModeCategoryCounts; // Number of points per category
  multipliers: number; // Number of multipliers per category
};

const ModeCategoryTable: React.FC<Props> = ({
  qsoCounts,
  pointsCounts,
  multipliers,
}) => {
  const categories: (keyof ModeCategoryCounts)[] = ["Phone", "CW", "Digital"];

  return (
    <div className="flex w-full">
      <table className="w-full border-collapse border border-gray-300">
        <thead className="dark:text-white text-[#2b2e48] font-[Barlow] text-sm">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left">
              {/* Metric */}
            </th>
            {categories.map((category) => (
              <th
                key={category}
                className="border border-gray-300 px-4 py-2 text-center bg-[#94a3b883] dark:bg-[#3C3C58]"
              >
                {category}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="font-[Barlow] text-sm">
          <tr>
            <td className="border border-gray-300 px-4 py-2 dark:text-white text-[#2b2e48] bg-[#94a3b883] dark:bg-[#3C3C58]">
              Number of QSOs
            </td>
            {categories.map((category) => (
              <td
                key={category}
                className="border border-gray-300 px-4 py-2 text-center dark:text-white text-[#2b2e48]"
              >
                {qsoCounts[category]}
              </td>
            ))}
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 dark:text-white text-[#2b2e48] bg-[#94a3b883] dark:bg-[#3C3C58]">
              Number of Points
            </td>
            {categories.map((category) => (
              <td
                key={category}
                className="border border-gray-300 px-4 py-2 text-center dark:text-white text-[#2b2e48]"
              >
                {pointsCounts[category]}
              </td>
            ))}
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 dark:text-white text-[#2b2e48] bg-[#94a3b883] dark:bg-[#3C3C58]">
              Number of Objectives Completed
            </td>

            <td
              className="border border-gray-300 px-4 py-2 text-start dark:text-white text-[#2b2e48]"
              colSpan={3}
            >
              {multipliers}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 dark:text-white text-[#2b2e48]">
              Total Score
            </td>
            <td
              className="border border-gray-300 px-4 py-2 text-start dark:text-white text-[#2b2e48]"
              colSpan={3}
            >
              {multipliers *
                (pointsCounts.CW + pointsCounts.Digital + pointsCounts.Phone + pointsCounts.Other)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ModeCategoryTable;
