import { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import { AddContactLogPopupProps } from "types/Component";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import { Tooltip } from "components/Tooltip";
import { UserAvatar } from "components/UserAvatar";
import { getCountryFromCallSign } from "firebase-config";
import CountryDxccFlag from "components/CountryDxccFlag";
import { DuplicateIndicator } from "components/LogbookTemplates/DuplicateIndicator";

const statsIcons: Record<string, string> = {
  distance: Icons.DoubleArrow,
  direction: Icons.CompassIcon,
  noOfContacts: Icons.NumberIcon,
  lastContact: Icons.CalenderIcon,
};

const statsTooltipMessages: Record<string, string> = {
  distance: "Distance to the contact",
  direction: "Direction/Azimuth to the contact",
  noOfContacts: "# of Times Contacted",
  lastContact: "Last Contacted Date",
};

export const AddContactLogPopup: FC<AddContactLogPopupProps> = ({ data }) => {
  const { addContactLogPopupSummary, userProfile, theirCallSign, flagCode, duplicateInfo } =
    data;
  const firstName = addContactLogPopupSummary?.firstName;
  const lastName = addContactLogPopupSummary?.lastName;
  const isCallSignMatch = addContactLogPopupSummary?.isCallSignMatch;
  const theirCallsign = addContactLogPopupSummary?.theirCallsign;
  const theirCountry = addContactLogPopupSummary?.theirCountry;
  const theirState = addContactLogPopupSummary?.theirState;
  const distance = addContactLogPopupSummary?.distance;
  const direction = addContactLogPopupSummary?.direction;
  const noOfContacts = addContactLogPopupSummary?.noOfContacts;
  const lastContact = addContactLogPopupSummary?.lastContact;

  const [countryFromCallSign, setCountryFromCallSign] = useState<string>("");
  const [theirCallSignFormValue, setTheirCallSignFormValue] =
    useState<string>("");

  useEffect(() => {
    setTheirCallSignFormValue(theirCallSign || "");
  }, [theirCallSign]);

  useEffect(() => {
    getCallSignInfo();
  }, [theirCallSignFormValue]);

  const getCallSignInfo = async () => {
    if (theirCallSignFormValue) {
      const countryData: any = await getCountryFromCallSign(
        theirCallSignFormValue
      );
      if (countryData && countryData?.data?.Country) {
        setCountryFromCallSign(countryData.data?.Country);
      }
    } else {
      setCountryFromCallSign("");
    }
  };

  if (
    !isCallSignMatch &&
    !userProfile &&
    !noOfContacts &&
    !theirCallSignFormValue
  )
    return null;

  const renderStatsItem = (key: string, value?: number | string) => {
    if (!value) return null;

    return (
      <Tooltip message={statsTooltipMessages[key]}>
        <div className="justify-start items-center gap-2.5 flex">
          <div className="w-5 h-5 relative">
            <img
              className="w-5 h-5 left-[-0px] top-0 absolute invert dark:filter-none dark:invert-0"
              src={statsIcons[key]}
              alt=""
            />
          </div>
          <div className="dark:text-white text-[#2b2e48] text-base font-normal font-Play">
            {key === "direction" ? `${value}\u00b0` : value}
          </div>
        </div>
      </Tooltip>
    );
  };

  const renderStats = () => {
    return (
      <>
        {renderStatsItem("distance", distance)}
        {renderStatsItem("direction", direction)}
        {renderStatsItem("noOfContacts", noOfContacts)}
        {renderStatsItem("lastContact", lastContact)}
      </>
    );
  };
  const renderCallSignInfo = () => {
    const countryName =
      theirCountry || userProfile?.country || countryFromCallSign || "";
    const stateName = theirState || userProfile?.state || "";
    const callSign =
      userProfile?.callSign || theirCallSignFormValue || theirCallsign || "";
    const profilePic = userProfile?.profilePic || "";
    const fullName =
      `${firstName ?? ""} ${lastName ?? ""}`.trim() ||
      `${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`.trim();

    return (
      <div
        className="flex flex-col justify-start items-center gap-[8px] cursor-pointer"
        onClick={() => {
          window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
            callSign
          )}?tab=Logbook&contact-tab=entries`;
        }}
      >
        {/* Profile Picture, Location and CallSign Section */}
        <div className="flex items-center gap-3">
          <div className="relative inline-flex">
            {profilePic ? (
              <img
                src={profilePic}
                alt="profile picture"
                className="relative inline-block h-12 w-12 rounded-full object-cover"
              />
            ) : (
              <UserAvatar className="relative inline-block h-12 w-12 rounded-full" />
            )}
            <div className="absolute bottom-1.5 right-1.5 grid min-h-[24px] min-w-[24px] translate-x-2/4 translate-y-2/4 place-items-center rounded-full bg-transparent py-1 px-1 text-xs font-medium leading-none text-white content-['']">
              <CountryDxccFlag
                countryCode={countryName}
                flagCode={flagCode || ""}
              />
            </div>
          </div>

          <div className="flex flex-col items-left gap-y-0">
            {/* CallSign */}
            <div className="bg-gradient-to-r from-indigo-400 to-cyan-300 bg-clip-text text-transparent text-xl font-bold">
              {callSign && fullName
                ? `${callSign} - ${fullName}`
                : `${callSign || fullName}`}
            </div>

            {/* Country and State Info*/}
            {(countryName || stateName) && (
              <div className="dark:text-white text-[#2b2e48] text-base font-normal font-Play leading-none">
                {countryName && stateName
                  ? `${countryName} - ${stateName}`
                  : `${countryName || stateName}`}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full fle dark:text-white text-[#2b2e48] mt-2">
      <div className="bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px] inline-flex">
        <div className="p-3 pr-16 dark:bg-[#151541] bg-white justify-start items-center gap-[25px] inline-flex flex-wrap">
          {renderCallSignInfo()}
          <div className="text-xl font-bold">:</div>
          {renderStats()}
          {duplicateInfo?.band && <DuplicateIndicator band={duplicateInfo.band} />}
        </div>
      </div>
    </div>
  );
};
