import { auth, sendAutoUpdateParksDataTrigger } from "firebase-config";
import { showToast } from "helpers/Toast";
import { useState } from "react";
import { ToastTypes } from "types/Component";

export const useAdmin = () => {
  const [exportEmailLoading, setExportEmailLoading] = useState(false)
  const triggerAutoUpdateParksData = async () => {
    try {
      showToast({
        message: "Auto update parks data triggered",
        type: ToastTypes.SUCCESS,
      });

      await sendAutoUpdateParksDataTrigger();
    } catch (error) {
      showToast({
        message: "Error triggering auto update parks data",
        type: ToastTypes.ERROR,
      });
    }
  };

  const downloadEmails = async (contestId: string) => {
    try {
      setExportEmailLoading(true)
      const response = await fetch(
        `https://us-central1-world-radio-league-prod.cloudfunctions.net/getContestantEmails?contestId=${contestId}&userId=${auth.currentUser?.uid}`
      );
      if (!response.ok) {
        // Handle error
        return;
      }
      const blob = await response.blob();

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "contest_emails.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportEmailLoading(false)

    } catch (error) {
      setExportEmailLoading(false)

      console.log("DOWNLOAD ERROR : ", error);
    }
  };

  return {
    triggerAutoUpdateParksData,
    downloadEmails,
    exportEmailLoading
  };
};
