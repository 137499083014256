import Icons from "assets/Icons";
import ClockDisplay from "./ClockDisplay";
import { LogBookQuickSettingItem } from "pages/Dashboard/LogBook/LogBookQuickSettings";
import { useState } from "react";
import { motion } from "framer-motion";
import useTheme from "hooks/useTheme.hook";
import useBandConditions from "hooks/useBandConditions.hook";

function SubHeader() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { theme } = useTheme();
  const { bandConditions, sigLevel } = useBandConditions();


  return (
    <div className="w-full flex flex-col md:flex-row items-center justify-between dark:bg-blue-900 dark:bg-opacity-40 bg-[white] border border-b border-[#f1f3f4] dark:border-none px-2 py-1 relative">
      <div className="px-4">
        <LogBookQuickSettingItem
          onItemClick={() => setDropdownOpen(!isDropdownOpen)}
          data={{
            id: 6,
            icon: Icons.AntennaIcon,
            label: "Band Conditions",
          }}
        />

        {/* Dropdown */}
        <motion.div
          initial={{ opacity: 0, y: -10, scaleY: 0.95 }}
          animate={{
            opacity: isDropdownOpen ? 1 : 0,
            y: isDropdownOpen ? 0 : -10,
            scaleY: isDropdownOpen ? 1 : 0.95,
          }}
          transition={{ duration: 0.2, ease: "easeOut" }}
          className={` absolute left-4 mt-2 w-64 ${
            theme === "dark" ? "glassmorphism" : "bg-white"
          } border dark:border-gray-700 rounded-lg shadow-lg p-2 origin-top z-50 ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          {bandConditions?.length > 0 ? (
            <>
              <table className="w-full text-left font-Play dark:text-white text-[#2b2e48]">
                <thead>
                  <tr className="dark:text-[#17F9DA] text-[#1e40af]">
                    <th className="px-2 py-1">Band</th>
                    <th className="px-2 py-1">Day</th>
                    <th className="px-2 py-1">Night</th>
                  </tr>
                </thead>
                <tbody>
                  {bandConditions?.map((item: any, index: number) => (
                    <tr key={index} className="text-sm">
                      <td className="px-2 py-1">{item.band}</td>
                      <td
                        className={`px-2 py-1 ${
                          item.day === "Poor"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {item.day || "-"}
                      </td>
                      <td
                        className={`px-2 py-1 ${
                          item.night === "Poor"
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {item.night || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="text-xs dark:text-white text-[#2b2e48] mt-2 px-2 py-1">
                Signal Noise: {sigLevel}
              </div>
            </>
          ) : (
            <p className="text-sm dark:text-white text-[#2b2e48]">
              No data available
            </p>
          )}
        </motion.div>
      </div>
      <ClockDisplay />
    </div>
  );
}

export default SubHeader;
