import React, { Fragment, useEffect, useState } from "react";

import Header from "components/Header";

import useTheme from "hooks/useTheme.hook";
import IntegrationItem from "components/IntegrationItem";
import Images from "assets/Images";
import Icons from "assets/Icons";

function IntegrationsArchive() {
  const { theme } = useTheme();

  const [os, setOs] = useState<"Windows" | "Mac" | "Other">("Other");

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("win")) {
      setOs("Windows");
    } else if (userAgent.includes("mac")) {
      setOs("Mac");
    }
  }, []);

  const downloadApp = (platform: string, intelArch?: boolean) => {
    const macLink =
      "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control.dmg";

    const windowsLink =
      "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL.exe";

    if (platform === "Mac") {
      window.open(macLink);
    } else if (platform === "Windows") {
      window.open(windowsLink);
    }
  };

  return (
    <main className="relative w-full h-full text-white">
      <Header title="Logbook" />
      <div className="w-full flex p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="md:px-14 md:py-10 px-3 py-3">
          <h1 className="dark:text-white text-[#2b2e48] text-[30px] font-bold font-[Barlow]">
            Archives
          </h1>
          <span className="dark:text-white text-[#2b2e48]">
            Download older version of WRL CAT Control.
          </span>
          <div className="mt-10">
            <h1 className="font-[Play] font-bold text-xl">
              Beta Version: 1.0.0
            </h1>
            <ul className="mt-3 ml-8">
              <li
                className="cursor-pointer underline"
                onClick={() => downloadApp("Windows")}
              >
                Windows: Download (1.0.0)
              </li>
              <li
                className="cursor-pointer mt-2 underline"
                onClick={() => downloadApp("Mac")}
              >
                MacOS: Download (1.0.0)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

export default IntegrationsArchive;
