import { all, fork } from "redux-saga/effects";

import {
  watchAppleLogin,
  watchDiscourseAuth,
  watchFacebookLogin,
  watchGoogleLogin,
  watchLoginUser,
  watchLogOutUser,
  watchRegisterUser,
  watchSendEmailToVerify,
} from "store/Sagas/authorization.saga";
import { watchGetUserProfileByCallSign } from "store/Sagas/guest.saga";
import {
  watchGetDistanceLeaderBoard,
  watchGetDXLeaderBoard,
  watchGetLeaderBoardByWeek,
  watchGetLeaderBoardData,
  watchGetLeaderBoardModeByWeek,
  watchGetStateLeader,
  watchGetUsersDataForLeaderBoardByMode,
} from "store/Sagas/leaderBoard.saga";

import {
  watchFetchUserNormalNotification,
  watchGetUserNotification,
  watchMarkUserNormalNotifications,
  watchUpdateUserNotification,
} from "store/Sagas/notification.saga";
import {
  watchDeleteUserLogBookContactData,
  watchEditUserLogBookContactData,
  watchGetAllUserContactData,
  watchGetLogBookUser,
  watchGetUserLogBookContactData,
  watchSaveUserLogBook,
  watchSaveUserLogBookContactData,
  watchFetchCallSignData,
  watchGetUserLogBookData,
  watchGetAllUserContacts,
  watchGetAllUserContactsWithPagination,
  watchGetAllUserContactsWithPaginate,
  watchGetPaginatedContacts,
  watchGetPaginatedContactsWithPagination,
  watchDeleteUserLogBookData,
  watchGetUserLogBookContactForAdifExport,
  watchSaveCoordinatesOnLogBook,
  watchHandleAdifContact,
  watchGetPaginatedLogbookContacts,
  watchFetchContactDetail,
  watchSearchLogbook,
  watchFetchDailyLeaders,
  watchCountryDataGrid,
  watchEditLogBook,
  watchFetchSavedLocation,
  watchAddSavedLocation,
  watchDeleteSavedLocation,
  watchAddRadios,
  watchFetchRadios,
  watchFetchAntennas,
  watchAddAntennas,
  watchDeleteRadios,
  watchDeleteAntennas,
  watchFetchAddContactLogPopupSummary,
  watchAddStations,
  watchGetAllUserStations,
  watchDeleteUserStation,
} from "store/Sagas/logBook.saga";
import {
  watchCoordinateChange,
  watchCheckProfileUser,
  watchCreateUserProfile,
  watchEditUserProfile,
  watchUpdateUserProfile,
  watchFetchUserAwards,
  watchCheckCallSignAvailability,
  watchWordpressLogin,
  watchSetFeaturedUserAward,
  watchFetchFeaturedMembers,
  watchFetchAwards,
  watchAddUserContest,
  watchRemoveAward,
  watchDisableUser,
  watchDeleteUser,
  watchEnableUser,
  watchUpdateSubscription,
  watchReAuthenticate,
} from "store/Sagas/profile.saga";
import {
  watchUploadImgFile,
  watchUploadScreenShotImage,
} from "store/Sagas/uploadFile.saga";
import {
  watchCreateCheckoutSession,
  watchFetchSubscriptionProduct,
  watchFetchSubscriptionStatus,
  watchCreatePortalSession,
  watchUpdateAutoRenew,
} from "store/Sagas/subscription.saga";
import {
  watchGetMembersData,
  watchGetMembersDataWithPagination,
  watchGetMapMembersData,
  watchGetGlobeMembersData,
} from "store/Sagas/members.saga";
import {
  watchStoreContestByAdmin,
  watchActiveFetchContest,
  watchPassedFetchContest,
  watchUpcomingFetchContest,
  watchFetchContestDetail,
  watchEnrollContest,
  watchGetUserContestRanking,
  // watchGetContestLeader,
  watchDeleteContest,
  watchGetContestLeaderBoard,
  watchGetContestContacts,
  watchGetContestantDetail,
  watchGetAwardContest,
  watchEditContest,
  watchUpdateContest,
  watchFetchTopBandByHour,
} from "store/Sagas/contest.saga";
import {
  watchCreateSpot,
  watchEditSpot,
  watchFetchSpot,
} from "./spotting.saga";
import {
  watchAddPotaAutoSpot,
  watchfetchActivitiesListView,
  watchfetchActivitiesMap,
  watchfetchSearchActivitiesListView,
  watchGetNearbyParkReferences,
  watchGetParkByReference,
  watchGetParkReferenceSuggestions,
} from "./pota.saga";
import {
  watchFetchCallsignFromFirestoreDB,
  watchFetchCallsignFromHamDB,
} from "./callsign.saga";
import {
  watchAddAward,
  watchFetchAwards as watchFetchAward,
} from "./award.saga";
import { watchQSOManager } from "./qsoManager.saga";
import { watchAnalytics } from "./analytics.saga";

export default function* root() {
  yield all([
    fork(watchRegisterUser),
    fork(watchLoginUser),
    fork(watchCheckProfileUser),
    fork(watchCreateUserProfile),
    fork(watchEditUserProfile),
    fork(watchLogOutUser),
    fork(watchGetLogBookUser),
    fork(watchSaveUserLogBook),
    fork(watchGoogleLogin),
    fork(watchFacebookLogin),
    fork(watchSaveUserLogBookContactData),
    fork(watchGetUserLogBookContactData),
    fork(watchUpdateUserProfile),
    fork(watchUploadImgFile),
    fork(watchDiscourseAuth),
    fork(watchCoordinateChange),
    fork(watchGetLeaderBoardData),
    fork(watchGetUsersDataForLeaderBoardByMode),
    fork(watchGetAllUserContactData),
    fork(watchCreateCheckoutSession),
    fork(watchFetchSubscriptionProduct),
    fork(watchFetchSubscriptionStatus),
    fork(watchCreatePortalSession),
    fork(watchGetUserProfileByCallSign),
    fork(watchFetchUserAwards),
    fork(watchCheckCallSignAvailability),
    fork(watchWordpressLogin),
    fork(watchEditUserLogBookContactData),
    fork(watchSendEmailToVerify),
    fork(watchGetUserNotification),
    fork(watchUpdateUserNotification),
    fork(watchSetFeaturedUserAward),
    fork(watchDeleteUserLogBookContactData),
    fork(watchFetchCallSignData),
    fork(watchGetUserLogBookData),
    fork(watchGetAllUserContacts),
    fork(watchUploadScreenShotImage),
    fork(watchGetMembersData),
    fork(watchGetMembersDataWithPagination),
    fork(watchGetAllUserContactsWithPagination),
    fork(watchGetAllUserContactsWithPaginate),
    fork(watchGetPaginatedContacts),
    fork(watchGetPaginatedContactsWithPagination),
    fork(watchFetchFeaturedMembers),
    fork(watchDeleteUserLogBookData),
    fork(watchGetUserLogBookContactForAdifExport),
    fork(watchSaveCoordinatesOnLogBook),
    fork(watchStoreContestByAdmin),
    fork(watchActiveFetchContest),
    fork(watchPassedFetchContest),
    fork(watchUpcomingFetchContest),
    fork(watchFetchContestDetail),
    fork(watchEnrollContest),
    fork(watchGetUserContestRanking),
    fork(watchFetchTopBandByHour),
    // fork(watchGetContestLeader),
    fork(watchDeleteContest),
    fork(watchGetContestLeaderBoard),
    fork(watchGetContestContacts),
    fork(watchGetContestantDetail),
    fork(watchGetAwardContest),
    fork(watchEditContest),
    fork(watchUpdateContest),
    fork(watchGetMapMembersData),
    fork(watchGetGlobeMembersData),
    fork(watchHandleAdifContact),
    fork(watchGetPaginatedLogbookContacts),
    fork(watchFetchAwards),
    fork(watchAddUserContest),
    fork(watchRemoveAward),
    fork(watchFetchUserNormalNotification),
    fork(watchMarkUserNormalNotifications),
    fork(watchGetLeaderBoardByWeek),
    fork(watchGetLeaderBoardModeByWeek),
    fork(watchGetDistanceLeaderBoard),
    fork(watchGetDXLeaderBoard),
    fork(watchGetStateLeader),
    fork(watchFetchContactDetail),
    fork(watchSearchLogbook),
    fork(watchDisableUser),
    fork(watchDeleteUser),
    fork(watchEnableUser),
    fork(watchUpdateSubscription),
    fork(watchFetchDailyLeaders),
    fork(watchCreateSpot),
    fork(watchFetchSpot),
    fork(watchEditSpot),
    fork(watchCountryDataGrid),
    fork(watchEditLogBook),
    fork(watchFetchSavedLocation),
    fork(watchAddSavedLocation),
    fork(watchDeleteSavedLocation),
    fork(watchAddRadios),
    fork(watchFetchRadios),
    fork(watchFetchAntennas),
    fork(watchAddAntennas),
    fork(watchDeleteRadios),
    fork(watchDeleteAntennas),
    fork(watchFetchAddContactLogPopupSummary),
    fork(watchGetParkByReference),
    fork(watchGetParkReferenceSuggestions),
    fork(watchGetNearbyParkReferences),
    fork(watchAddPotaAutoSpot),
    fork(watchFetchCallsignFromHamDB),
    fork(watchFetchCallsignFromFirestoreDB),
    fork(watchUpdateAutoRenew),
    fork(watchFetchAward),
    fork(watchAddAward),
    fork(watchAddStations),
    fork(watchGetAllUserStations),
    fork(watchDeleteUserStation),
    fork(watchAppleLogin),
    fork(watchfetchActivitiesMap),
    fork(watchQSOManager),
    fork(watchfetchActivitiesListView),
    fork(watchfetchSearchActivitiesListView),
    fork(watchAnalytics),
    fork(watchReAuthenticate),
  ]);
}
