import React from "react";
import Icons from "assets/Icons";

import { useDispatch } from "react-redux";
import { setAddSpotModal } from "store/Actions/logBook.actions";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useSpotting from "hooks/spotting.hook";
import { SPOT_ACTION_TYPE } from "types/Functions";
const AddSpotModal = () => {
  const dispatch = useDispatch();

  const {
    myCallSign,
    setMyCallSign,
    theirCallSign,
    setTheirCallSign,
    myLocation,
    setMyLocation,
    theirCountry,
    setTheirCountry,
    theirState,
    setTheirState,
    theirGrid,
    setTheirGrid,
    frequency,
    setFrequency,
    mode,
    setMode,
    comments,
    setComments,
    getUserCountryFromCallSign,
    onFindMyLocationClicked,
    saveSpot,
    onFrequencyBlur,
    editMode,
    respotSpot,
    spotLoading,
    userProfile,
  } = useSpotting();

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-y-auto carousel-scrollbar">
      <div className="w-full min-h-fit">
        <div className="w-full">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white px-6 py-5 dark:text-white text-[#2b2e48] font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer z-[1001]"
              onClick={() => {
                dispatch(setAddSpotModal(false));
              }}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 invert bg-black dark:filter-none dark:invert-0 z-[1005] rounded-full"
                alt="close"
              />
            </div>
            <p className="absolute right-8 top-3 font-bold text-sm">
              You have added{" "}
              <span className="text-[#00FFFF]">
                {(userProfile?.spotsCreated as number) || 0}
              </span>{" "}
              spots!
            </p>
            <h5 className="font-[Play] font-bold dark:text-white text-[#2b2e48] py-2 ml-3 w-full text-lg">
              Add Spot
            </h5>
            <div className="px-1 md:px-14 mt-10">
              <div className="flex justify-between">
                <TitledInputDefault
                  setText={(value) => setMyCallSign(value.target.value)}
                  name="myCallSign"
                  title="My Callsign"
                  placeHolder="My Callsign"
                  value={myCallSign}
                  tabindex={2}
                  className={"mb-1"}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]"
                  error={undefined}
                />
                <TitledInputDefault
                  setText={(value) =>
                    setTheirCallSign(value.target.value.toUpperCase())
                  }
                  name="thierCallsign"
                  title="Their Callsign"
                  placeHolder="Their Callsign"
                  value={theirCallSign}
                  tabindex={2}
                  className={"mb-1"}
                  onBlur={() => {
                    if (theirCallSign) {
                      getUserCountryFromCallSign(theirCallSign);
                    }
                  }}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]"
                  error={undefined}
                />
              </div>
              <div className="flex justify-between">
                <TitledInputDefault
                  setText={(value) => setMyLocation(value.target.value)}
                  name="myLocation"
                  title="My Location"
                  placeHolder="My Location"
                  value={myLocation}
                  tabindex={2}
                  className={"mb-1"}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]"
                  error={undefined}
                />
                <TitledInputDefault
                  setText={(value) => setTheirCountry(value.target.value)}
                  name="thierCountry"
                  title="Their Country"
                  placeHolder="Their Country"
                  value={theirCountry}
                  tabindex={2}
                  className={"mb-1"}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]"
                  error={undefined}
                />
              </div>
              <div className="flex justify-between items-center">
                <BorderButtonSecondary
                  isLoading={false}
                  text={"Find My Location"}
                  onClick={onFindMyLocationClicked}
                  childClassName="text-sm"
                  className="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%] mt-3"
                />
                <TitledInputDefault
                  setText={(value) => setTheirState(value.target.value)}
                  name="theirState"
                  title="Their State (Optional)"
                  placeHolder="Their State (Optional)"
                  value={theirState}
                  tabindex={2}
                  className={"mb-1"}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]"
                  error={undefined}
                />
              </div>
              <div className="flex justify-between items-center">
                <TitledInputDefault
                  setText={(value) => setComments(value.target.value)}
                  name="comments"
                  title="Comments"
                  placeHolder="Comments"
                  value={comments}
                  tabindex={2}
                  className={"mb-1 h-[200px]"}
                  width="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]"
                  error={undefined}
                  multiple
                />
                <div className="w-[48.5%] sm:w-[23.5%] md:w-[45.7%] xl:w-[45%]">
                  <TitledInputDefault
                    setText={(value) => setTheirGrid(value.target.value)}
                    name="theirGrid"
                    title="Their Grid Square (Optional)"
                    placeHolder="Their Grid Square (Optional)"
                    value={theirGrid}
                    tabindex={2}
                    className={"mb-1"}
                    width="w-[100%]"
                    error={undefined}
                  />
                  <TitledInputDefault
                    setText={(value) => setFrequency(value.target.value)}
                    name="frequency"
                    title="Frequency"
                    placeHolder="Frequency"
                    value={frequency}
                    tabindex={2}
                    className={"mb-1"}
                    width="w-[100%]"
                    error={undefined}
                    onBlur={onFrequencyBlur}
                  />
                  <TitledInputDefault
                    setText={(value) => setMode(value.target.value)}
                    name="mode"
                    title="Mode"
                    placeHolder="Mode"
                    value={mode}
                    tabindex={2}
                    className={"mb-1"}
                    width="w-[100%]"
                    error={undefined}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={spotLoading}
                text={editMode ? "Re-Spot" : "Save"}
                onClick={() =>
                  editMode ? respotSpot() : saveSpot(SPOT_ACTION_TYPE.SPOT_THEM)
                }
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>

            {/* {isAdminControlLoading ? (
              <div className="w-full h-full bg-[#000] opacity-60 absolute top-0 left-0">
                <ActivityIndicator size={40} />
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddSpotModal;
