import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import TabNavGlow from "components/TabNavGlow";
import useTheme from "hooks/useTheme.hook";
import AwardsDashboard from "./AwardsDashboard";
import { clearAnalytics } from "store/Actions/analytics.actions";
import Header from "components/Header";

const Analytics: React.FC = () => {
  const { theme } = useTheme();
  const { tab } = useParams<{ tab: string }>();
  const [selectedTab, setSelectedTab] = useState<string>("DX Dashboard");
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
    // Cleanup function to reset first visit flag when component unmounts
    return () => {
      setIsFirstVisit(true);
    };
  }, [tab]);

  const tabs = ["DX Dashboard", "US States"];

  const handleTabChange = (newTab: string) => {
    // dispatch(clearAnalytics());
    setSelectedTab(newTab);
    setIsFirstVisit(false);
    navigate(`/analytics/${newTab.toLowerCase().replace(/ /g, "-")}`);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "DX Dashboard":
        return <AwardsDashboard type="dx" calculateAwards={isFirstVisit} />;
      case "US States":
        return <AwardsDashboard type="us-states" calculateAwards={isFirstVisit} />;
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Analytics" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper">
        <div className="h-full w-full">
          <div
            className={`${
              theme === "dark" ? "glassmorphism" : "bg-white"
            } w-full h-fit pb-6`}
          >
            {/* Navigation */}
            <div className="h-fit">
              <div className="w-full flex flex-col md:flex-row md:justify-between pe-8">
                <TabNavGlow
                  selectedIndex={selectedTab}
                  setSelectedIndex={handleTabChange}
                  tabs={tabs}
                />
              </div>
            </div>

            {/* Divider */}
            <div className="h-[0.5px] w-full bg-[#1b1d63] mb-1" />

            {/* Content */}
            <div className="mt-4">{renderContent()}</div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Analytics;
