import { doc, getDoc } from "@firebase/firestore";
import { db } from "firebase-config";
import { useEffect, useState } from "react";

function transformBandConditions(
  data: Array<{ name: string; _: string; time: string }>
): Array<{ band: string; day: string | null; night: string | null }> {
  const bandMap = data.reduce((acc, current) => {
    const { name, _: condition, time } = current;
    const bandName = name;

    if (!acc[bandName]) {
      acc[bandName] = {
        band: bandName,
        day: null,
        night: null,
      };
    }

    if (time === "day") {
      acc[bandName].day = condition;
    } else if (time === "night") {
      acc[bandName].night = condition;
    }

    return acc;
  }, {} as { [key: string]: { band: string; day: string | null; night: string | null } });

  return Object.values(bandMap);
}

function useBandConditions() {
  const [bandConditions, setBandConditions] = useState<any>();
  const [sigLevel, setSigLevel] = useState<string>();

  useEffect(() => {
    const fetchBandConditions = async () => {
      try {
        const docRef = doc(db, "Analytics", "BandConditions");

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const reportData = docSnap.data();

          const data = transformBandConditions(
            reportData?.data?.calculatedconditions?.band as any
          );

          setSigLevel(reportData?.data?.signalnoise);
          setBandConditions(data);
        }
      } catch (err) {
        console.log("Error fetching band conditions:", err);
      }
    };

    fetchBandConditions();
  }, []);

  return { bandConditions, sigLevel };
}

export default useBandConditions;