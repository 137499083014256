import {
  DiscourseSSOParams,
  LoginActionParams,
  RegisterActionParams,
} from "types/State";
import {
  auth,
  db,
  googleProvider,
  facebookProvider,
  getDiscourseSSO,
  signInWithPopup
} from "firebase-config";
import * as functions from "firebase/functions";

import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  updateEmail,
  User,
  UserCredential,
  OAuthProvider,
} from "firebase/auth";
import { UserDataTable } from "constants/Collections";
import {
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
} from "firebase/firestore";
import { safeEmail } from "helpers/Utils";
interface AuthServiceType {
  registerUser(data: RegisterActionParams): Promise<UserCredential>;
  loginUser(data: LoginActionParams): Promise<UserCredential>;
  loginWithGmail(): Promise<UserCredential>;
  loginWithApple(): Promise<UserCredential>;
  loginWithFacebook(): Promise<UserCredential>;
  getUserData(uid: string): Promise<DocumentSnapshot<DocumentData>>;
  logOut(): Promise<void | null>;
  verifyUser(email: string): Promise<void>;
  updateEmail(currentUser: User, email: string): Promise<any>;
  discourseAuth(
    data: DiscourseSSOParams
  ): Promise<functions.HttpsCallableResult<unknown> | null>;
}

const AuthService: AuthServiceType = {
  registerUser: async (data) => {
    const { email, password } = data;
    return await createUserWithEmailAndPassword(auth, email, password);
  },
  loginUser: async (data) => {
    const { email, password } = data;
    const sanitizedEmail = safeEmail(email);
    return await signInWithEmailAndPassword(auth, sanitizedEmail, password);
  },
  verifyUser: async (email) => {
    const sanitizedEmail = safeEmail(email);
    return await sendPasswordResetEmail(auth, sanitizedEmail);
  },
  updateEmail: async (currentUser, email) => {
    const sanitizedEmail = safeEmail(email);
    return await updateEmail(currentUser, sanitizedEmail);
  },
  loginWithGmail: async () => {
    googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    return await signInWithRedirect(auth, googleProvider);
  },
  loginWithApple: async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return await signInWithPopup(auth, provider);
  },
  loginWithFacebook: async () => {
    return await signInWithRedirect(auth, facebookProvider);
  },
  getUserData: async (uid) => {
    const cityRef = doc(db, UserDataTable, uid);
    return await getDoc(cityRef);
  },
  logOut: async () => {
    try {
      return await signOut(auth);
    } catch (e) {
      return null;
    }
  },
  discourseAuth: async (data) => {
    try {
      return await getDiscourseSSO({
        discoursePayload: data.sso,
        discourseSignature: data.sig,
        idToken: data.idToken,
      });
    } catch (e) {
      return null;
    }
  },
};

export default AuthService;
