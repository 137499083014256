import React from "react";
import Switch from "react-switch";

interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  height?: number;
  width?: number;
  handleDiameter?: number;
  onColor?: string;
  offColor?: string;
  icon?: any;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  checked,
  onChange,
  height = 28,
  width = 40,
  handleDiameter = 12,
  onColor = "#15B86B",
  offColor = "#828282",
  icon,
}) => {
  return (
    <button
      className="w-[150px] h-[30px] flex items-center justify-between rounded-sm px-3 cursor-pointer border border-[#333679] bg-gradient-to-r from-[#1a1d54] to-[#2e2e56] hover:from-[#2e2e56] hover:to-[#1a1d54] transition-all duration-150 ease-in-out shadow-inner no-select"
      onClick={(e) => {
        e.stopPropagation();
        onChange(checked);
      }}
    >
      <div className="flex items-center">
        {icon ? (
          <img
            src={icon}
            alt=""
            className="w-[16px] h-[16px] mr-1 filter dark:filter-none invert-0"
          />
        ) : null}
        <span className="text-white font-Play text-sm mr-2">{label}</span>
      </div>
      <Switch
        onChange={() => {}}
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={height}
        width={width}
        onColor={onColor}
        offColor={offColor}
        handleDiameter={handleDiameter}
        checkedHandleIcon={<div className="w-full h-full rounded-full" />}
        uncheckedHandleIcon={<div className="w-full h-full rounded-full" />}
      />
    </button>
  );
};

export default ToggleSwitch;
