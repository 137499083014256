import React, { useState, useEffect } from "react";
import Icons from "assets/Icons";
import Images from "assets/Images";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { FaArrowRight } from "react-icons/fa";

export type IntegrationItemProps = {
  data: any;
  os: string;
  downloadApp: (platform: string, arch?: boolean) => void;
};

function IntegrationItem({ data, os, downloadApp }: IntegrationItemProps) {
  return (
    <div className="md:w-1/2 w-[100%] sm:w-1/2 xl:w-1/2  mb-6">
      <div className="bg-gradient-to-b from-[#141761] to-[#0D0F4B]  w-[96%] min-h-[350px] rounded-xl border-[2px] border-[#2C2E71] md:py-5 py-5 md:px-5 px-5 font-[Barlow] mb-6 ">
        <img src={data.image} alt="integration" className="w-14 h-13" />
        <h2 className="dark:text-white text-[#fff] mt-3 text-[30px]">
          {data.name}
        </h2>
        <p className="dark:text-[#71717A] text-[#71717A]">{data.description}</p>
        <div className="flex items-center justify-between mt-4">
          {data.type === "download" ? (
            <div className="flex flex-col w-full">
              <div className="flex items-end w-[100%] mt-4 md:mt-1 ">
                {os === "Windows" ? (
                  <BorderButtonSecondary
                    isLoading={false}
                    text={"Download for Windows"}
                    onClick={() => {
                      downloadApp(os);
                    }}
                    tabIndex={8}
                    childClassName="text-sm"
                    className={""}
                  />
                ) : os === "Mac" ? (
                  <div className="flex flex-col w-full">
                    <BorderButtonSecondary
                      isLoading={false}
                      text={"Download for Mac (Apple Silicon)"}
                      onClick={() => {
                        downloadApp(os);
                      }}
                      tabIndex={8}
                      childClassName="text-sm"
                      className={"mb-2"}
                    />
                    <BorderButtonSecondary
                      isLoading={false}
                      text={"Download for Mac (Intel)"}
                      onClick={() => {
                        downloadApp(os, true);
                      }}
                      tabIndex={8}
                      childClassName="text-sm"
                      className={""}
                    />
                  </div>
                ) : null}
              </div>
              <button
                className="text-white mt-3 text-[12px] text-underlined"
                onClick={() =>
                  downloadApp(os === "Windows" ? "Mac" : "Windows")
                }
              >
                Download for {os === "Windows" ? "Mac" : "Windows"}
              </button>
            </div>
          ) : (
            <h2 className="text-white text-sm">
              Included on the WRL CAT Control App{" "}
            </h2>
          )}

          <a
            className="flex ml-4 items-center cursor-pointer"
            href={data.guideLink}
            target="_blank"
            rel="noreferrer"
          >
            <h2 className="text-white text-sm">Integration details </h2>
            <FaArrowRight color="#fff" />
          </a>
        </div>
        {data.type === "download" ? (
          <a
            className="text-white text-[12px] mt-4 cursor-pointer"
            href="/integration/archive"
          >
            Download older versions
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default IntegrationItem;
