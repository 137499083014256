import {
  LogBookContactTable,
  LogBookTable,
  UserDataTable,
  QsoMapShares,
  SavedLocations,
  UserRadios,
  UserAntennas,
  UserStations,
  OfflineMobileSync,
  ContestTable,
  EnrolledUsers,
} from "constants/Collections";
import {
  auth,
  calculateContactDistance,
  db,
  getAnalyticsData,
  getDailyLeaders,
  getQSOManagerContacts,
  updateContestContactPoints,
} from "firebase-config";
import {
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  doc,
  deleteDoc,
  increment,
  getDoc,
  DocumentData,
  limit,
  getCountFromServer,
  endBefore,
  limitToLast,
  startAfter,
  writeBatch,
  startAt,
  addDoc,
  Timestamp,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { getTimeFromNow } from "helpers/Date/Time";
import { allErrorHandler } from "helpers/ErrorHandler";
import { showToast } from "helpers/Toast";
import {
  buildDynamicContactQuery,
  buildDynamicContactQueryForPaginationCount,
  buildDynamicContactQueryForPaginationNext,
  buildDynamicContactQueryForPaginationPrev,
  compareObjects,
  getFormattedDate,
  getStateData,
  getStateLongName,
  getStateShortCode,
  sanitizeData,
} from "helpers/Utils";
import { ToastTypes } from "types/Component";
import {
  CallSignLookUpData,
  LogBookContactModel,
  LogBookModel,
  UserProfile,
  QSOMapShare,
  SavedLocation,
  Radios,
  Antenna,
  Stations,
} from "types/Models";
import {
  NewLogBook,
  UserContactPaginationProp,
  UserProfileContactsWithPagination,
  RecentContactData,
  AddContactLogPopupSummary,
} from "types/State";
import { deleteLogbookRecords } from "firebase-config";
import moment from "moment";
import { timeStamp } from "console";
interface LogBookServiceType {
  createUserLogBook(data: NewLogBook | any): Promise<null | LogBookModel>;
  getUserLogBooks(uid: string): Promise<LogBookModel[] | null>;
  createUserLogBookContactData(data: LogBookContactModel): Promise<boolean>;
  editUserLogBookContactData(
    data: LogBookContactModel,
    prevValues: LogBookContactModel,
    logbook: LogBookModel,
    cb?: any
  ): Promise<boolean>;
  editUserLogBookData(
    logbookData: LogBookModel,
    logBookId: string
  ): Promise<boolean>;
  batchUpdateNameByLogBookId(logBookId: string, name: string): Promise<void>;
  batchUpdateContestNameByContestId(
    contestId: string,
    name: string
  ): Promise<void>;
  deleteUserLogBookContactData(
    contactId: string,
    logBookId: string,
    userProfile: UserProfile,
    contact: LogBookContactModel
  ): Promise<boolean>;
  deleteUserLogBookData(
    logBookId: string,
    contestId?: string
  ): Promise<boolean>;
  getUserLogBookContacts(uid: string, data: any): Promise<LogBookModel[]>;
  getUserLogBook(logBookId: string): Promise<LogBookModel | null>;
  getAllUserContacts(uid: string): Promise<LogBookModel[]>;
  getAllUserContactsById(payload: any): Promise<LogBookModel[]>;
  fetchCallSignData(callSign: string): Promise<CallSignLookUpData | null>;
  getAllUserContactsByIdForPagination(
    uid: string
  ): Promise<UserProfileContactsWithPagination | null>;
  getPaginatedUserContactsById(
    payload: any
  ): Promise<UserProfileContactsWithPagination | null>;
  getGetPaginatedContacts(conditions: any): Promise<RecentContactData | null>;
  getGetPaginatedContactsWithPagination(
    conditions: any
  ): Promise<RecentContactData | null>;
  getPaginatedLogbookContactsById(
    conditions: any,
    userId: string
  ): Promise<UserProfileContactsWithPagination | null>;
  getAdiLogbooks(): Promise<LogBookModel[] | null>;
  fetchContactDetail(contactId: string): Promise<LogBookContactModel | null>;
  editContact(contactId: string, data: any): Promise<boolean>;
  searchLogbookContacts(payload: any): Promise<LogBookContactModel[] | null>;
  getHomeDailyLeaders(): Promise<any>;
  shareQsoMap(data: QSOMapShare): Promise<string | null>;
  fetchSavedLocations(uid: string): Promise<SavedLocation[] | null>;
  addSavedLocation(data: SavedLocation): Promise<boolean | string>;
  deleteSavedLocation(id: string): Promise<boolean>;
  addRadio(data: Radios): Promise<boolean>;
  fetchRadios(uid: string): Promise<Radios[] | null>;
  fetchAntennas(uid: string): Promise<Antenna[] | null>;
  addAntenna(data: Antenna): Promise<boolean>;
  deleteRadios(id: string): Promise<boolean>;
  deleteAntenna(id: string): Promise<boolean>;
  fetchAddContactLogPopupSummary(
    conditions: any
  ): Promise<AddContactLogPopupSummary | null>;
  addStation(data: Stations): Promise<boolean>;
  deleteStation(id: string): Promise<boolean>;
  fetchStations(uid: string): Promise<Stations[] | null>;
  getLogbookContactCount(
    uid: string,
    logBookId: string
  ): Promise<number | null>;
  getQSOManagerContacts(data: any): Promise<LogBookContactModel[] | null>;
  getAnalytics(data: any): Promise<any>;
  checkForDuplicate(contactData: LogBookContactModel, options?: any): Promise<{ band: string; timestamp: any } | null>;
}

const LogBookService: LogBookServiceType = {
  getUserLogBook: async (logBookId) => {
    const logBookRef = doc(db, LogBookTable, logBookId);
    const logBookData: DocumentData = await getDoc(logBookRef);
    if (logBookData.exists()) {
      return {
        ...logBookData.data(),
        id: logBookId,
      } as LogBookModel;
    } else {
      return null;
    }
  },
  getAllUserContactsByIdForPagination: async (uid) => {
    try {
      const q: any = query(
        collection(db, LogBookContactTable),
        where("uid", "==", uid),
        orderBy("contactTimeStamp", "desc"),
        limit(50)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const contacts: LogBookContactModel[] = querySnapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...(doc.data() as LogBookContactModel),
            };
          }
        );
        const countQuery = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          orderBy("contactTimeStamp", "desc")
        );
        const count = await getCountFromServer(countQuery);
        return {
          contacts: contacts,
          count: count?.data()?.count,
          lastDoc: contacts[contacts?.length - 1],
          firstDoc: contacts[0],
          page: 1,
        };
      } else {
        return null;
      }
    } catch (e) {
      console.log("getAllUserContactsByIdForPagination ERROR : ", e);
      return null;
    }
  },
  getPaginatedUserContactsById: async (payload) => {
    try {
      const conditions = payload?.conditions || payload;
      const uid = payload?.uid || conditions?.uid;
      const userId: string | undefined = uid || auth.currentUser?.uid;
      let q: any;
      // console.log("conditions PROFILE: ", conditions);
      if (conditions?.move === -1) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", userId),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          endBefore(conditions?.firstDoc["contactTimeStamp"]),
          limitToLast(50)
        );
      } else if (conditions.move === 0) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", userId),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          startAt(conditions?.firstDoc["contactTimeStamp"]),
          limit(50)
        );
      } else if (conditions.move === -2) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", userId),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          limit(50)
        );
        // if (conditions.firstDoc) {
        //   q = query(q, startAfter(conditions?.firstDoc["timestamp"]));
        // }
      } else {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", userId),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          startAfter(conditions?.lastDoc["contactTimeStamp"]),
          limit(50)
        );
      }

      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const contacts: LogBookContactModel[] = querySnapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...(doc.data() as LogBookContactModel),
            };
          }
        );
        const countQuery = query(
          collection(db, LogBookContactTable),
          where("uid", "==", userId),
          orderBy("contactTimeStamp", "desc")
        );
        const count = await getCountFromServer(countQuery);
        return {
          contacts: contacts,
          count: count?.data()?.count,
          lastDoc: contacts[contacts?.length - 1],
          firstDoc: contacts[0],
          page: conditions?.page,
        };
      } else {
        return null;
      }
    } catch (e) {
      console.log("getPaginatedUserContactsById ERROR : ", e);
      return null;
    }
  },
  getGetPaginatedContacts: async (conditions) => {
    try {
      let q: any = buildDynamicContactQuery(conditions);
      q = query(q, where("adiImported", "==", false));
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const contacts: LogBookContactModel[] = querySnapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...(doc.data() as LogBookContactModel),
            };
          }
        );
        let countQuery = buildDynamicContactQueryForPaginationCount(conditions);
        countQuery = query(countQuery, where("adiImported", "==", false));

        const count = await getCountFromServer(countQuery);
        return {
          contacts: contacts,
          count: count?.data()?.count,
          lastDoc: contacts[contacts?.length - 1],
          firstDoc: contacts[0],
          page: 1,
        };
      } else {
        return null;
      }
    } catch (e) {
      console.log("getGetPaginatedContacts ERROR : ", e);
      return null;
    }
  },
  getGetPaginatedContactsWithPagination: async (conditions) => {
    try {
      let q;
      if (conditions?.move !== -1) {
        q = buildDynamicContactQueryForPaginationNext(
          conditions?.conditions,
          conditions?.doc?.lastDoc["timestamp"]
        );
        // q = query(
        //   collection(db, UserDataTable),
        //   orderBy("timestamp", "desc"),
        //   startAfter(payload?.doc?.lastDoc["timestamp"]),
        // );
      } else {
        q = buildDynamicContactQueryForPaginationPrev(
          conditions?.conditions,
          conditions?.doc?.firstDoc["timestamp"]
        );
        // q = query(
        //   collection(db, UserDataTable),
        //   orderBy("timestamp", "desc"),
        //   endBefore(payload?.doc?.firstDoc["timestamp"]),
        // );
      }
      q = query(q, where("adiImported", "==", false));

      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const contacts: LogBookContactModel[] = querySnapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...(doc.data() as LogBookContactModel),
            };
          }
        );
        let countQuery = buildDynamicContactQueryForPaginationCount(
          conditions?.conditions
        );
        countQuery = query(countQuery, where("adiImported", "==", false));
        const count = await getCountFromServer(countQuery);
        return {
          contacts: contacts,
          count: count?.data()?.count,
          firstDoc: contacts[0],
          lastDoc: contacts[contacts?.length - 1],
          page: conditions?.page,
        };
      } else {
        return null;
      }
    } catch (e) {
      console.log("getGetPaginatedContactsWithPagination ERROR : ", e);
      return null;
    }
  },
  createUserLogBook: async (data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      let logBookData = {
        ...data,
        uid: uid,
        timestamp: serverTimestamp(),
      };
      if (uid) {
        const logBookRef = doc(collection(db, LogBookTable));

        await setDoc(logBookRef, {
          ...sanitizeData(logBookData),
          timestamp: serverTimestamp(),
        });
        return {
          id: logBookRef.id,
          ...data,
          uid: uid,
          timestamp: serverTimestamp(),
        } as LogBookModel;
      } else {
        return null;
      }
    } catch (e) {
      console.log("ERROR WHILE SAVING NEW LOGBOOK: ", e);
      return null;
    }
  },
  getUserLogBooks: async (uid) => {
    const arr: LogBookModel[] = [];
    try {
      if (uid) {
        const q = query(
          collection(db, LogBookTable),
          where("uid", "==", uid),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: LogBookModel = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
          return arr;
        } else {
          return arr;
        }
      } else {
        return arr;
      }
    } catch (e) {
      return null;
    }
  },
  createUserLogBookContactData: async (data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      const sanitizedData = sanitizeData(data);
      const timeString = sanitizedData.time;
      const timeStringSplit = timeString.split(":");
      if (timeStringSplit.length === 2) {
        timeStringSplit.push("00");
      }
      const timeStringWithSeconds = timeStringSplit.join(":");
      const dateTimeStr = `${getFormattedDate(
        data.date
      )} ${timeStringWithSeconds}`;
      let logBookData = {
        ...sanitizedData,
        uid: uid,
        timestamp: serverTimestamp(),
        contactTimeStamp: Timestamp.fromMillis(
          moment.utc(dateTimeStr, "YYYY-MM-DD HH:mm:ss", true).valueOf()
        ),
        createdAt: Date.now(),
      };

      if (uid && data.logBookId) {
        // Start a batch write
        const batch = writeBatch(db);

        // Reference to the LogBookContactTable collection
        const contactRef = collection(db, LogBookContactTable);
        // Create a new document in LogBookContactTable with logBookData
        batch.set(doc(contactRef), logBookData);
        // Reference to the document in LogBookTable

        //get count of all contacts inside LogBookContactTable
        const q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", data.logBookId),
          orderBy("contactTimeStamp", "desc")
        );
        const queryCount = await getCountFromServer(q);
        const count = queryCount?.data()?.count || 0;
        const logBookRef = doc(db, LogBookTable, `${data.logBookId}`);
        // Update the document in LogBookTable
        batch.set(
          logBookRef,
          {
            contactCount: count + 1,
            myRadio: data?.myRadio,
            myAntenna: data?.myAntenna,
          },
          { merge: true }
        );

        // Commit the batch write
        try {
          await batch.commit();
          // allErrorHandler("added successfully");
          return true;
        } catch (error) {
          console.error("Error in batch write: ", error);
          allErrorHandler("something went wrong");
          return false;
        }
      } else {
        allErrorHandler("something went wrong");
        return false;
      }
    } catch (e) {
      console.log("ERROR WHILE SAVING NEW LOGBOOK CONTACT: ", e);
      allErrorHandler("something went wrong");
      return false;
    }
  },
  editUserLogBookContactData: async (data, prevValues, logbook, cb) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      const contactId = prevValues.id ? prevValues.id : "";

      if (uid) {
        const batch = writeBatch(db);

        const contactRef = doc(db, LogBookContactTable, contactId);

        if (
          data.country !== prevValues?.country ||
          data?.state !== prevValues?.state
        ) {
          data.theirCountry = data.country;
          data.theirState = data.state;
          data.theirCity = data.state;
        }

        const timeString = data.time || "";
        const timeStringSplit = timeString.split(":");
        if (timeStringSplit.length === 2) {
          timeStringSplit.push("00");
        }
        const timeStringWithSeconds = timeStringSplit.join(":");
        const dateTimeStr = `${getFormattedDate(
          data.date
        )} ${timeStringWithSeconds}`;
        if (dateTimeStr) {
          data.contactTimeStamp = Timestamp.fromMillis(
            moment.utc(dateTimeStr, "YYYY-MM-DD HH:mm:ss", true).valueOf()
          );
        }

        const stateData = getStateData(data?.state || "");

        if (data?.state && stateData) {
          data.stateLongName = stateData?.name;
          data.state = stateData?.shortCode;
          data.theirState = stateData?.shortCode;
        }

        // if their park is object, that means user has selected 1 or more parks
        if (typeof data?.theirParks === "object") {
          const theirParks = data?.theirParks as Record<string, any>;

          if (Object.keys(theirParks || {})?.length) {
            const [firstTheirPark] = Object.values(theirParks || {});

            const theirCoordinates =
              firstTheirPark?.latitude && firstTheirPark?.longitude
                ? {
                    latitude: (firstTheirPark?.latitude || 0) as any,
                    longitude: (firstTheirPark?.longitude || 0) as any,
                  }
                : null;

            const theirLocation = {
              grid: firstTheirPark?.grid6 || "",
              state: getStateShortCode(firstTheirPark?.locationName || ""),
              stateLongName: getStateLongName(
                firstTheirPark?.locationName || ""
              ),
              country: firstTheirPark?.entityName || "",
            };

            if (theirCoordinates) {
              data.theirCoordinates = theirCoordinates;
            }
            data.grid = theirLocation.grid;
            data.state = theirLocation.state;
            data.country = theirLocation.country;
          }

          let theirParksData = theirParks;
          const previousTheirPark = (prevValues as any)?.theirParks;

          if (
            previousTheirPark &&
            typeof previousTheirPark === "object" &&
            Object.keys(previousTheirPark || {})?.length
          ) {
            const { added, removed } = compareObjects(
              theirParks || {},
              previousTheirPark || {}
            );

            if (removed?.length > 0) {
              const newMyParks = { ...theirParks };

              removed.forEach((key) => {
                newMyParks[key] = deleteField() as any;
              });
              theirParksData = newMyParks;
            }
          }
          data.theirParks = theirParksData;
        }
        data.updatedAt = Timestamp.now();
        batch.set(contactRef, data, { merge: true });

        if (prevValues.adiImported) {
          if (prevValues.adiError) {
            const logBookRef = doc(db, LogBookTable, `${prevValues.logBookId}`);
            const userRefRef = doc(db, UserDataTable, `${uid}`);

            let data: any = {
              contactCount: increment(1),
              failedContacts: increment(-1),
              successContacts: increment(1),
            };
            if (logbook.failedContacts === 1) {
              data.importStatus = "Complete";
              data.errorCode = null;
              data.errorLogs = null;
            }
            batch.set(logBookRef, data, { merge: true });
            batch.set(
              userRefRef,
              {
                numberOfContactsImported: increment(1),
              },
              { merge: true }
            );
          }
        }
        const mobileOfflineSyncData = {
          uid: uid,
          itemId: contactId,
          collection: LogBookContactTable,
          operationType: "UPDATE",
          timestamp: serverTimestamp(),
          data: data,
          mobileSyncStatus: "PENDING",
        };
        //add to offline sync
        const offlineSyncRef = collection(db, OfflineMobileSync);
        batch.set(doc(offlineSyncRef), mobileOfflineSyncData);
        await batch.commit();

        const newLogBookId = data?.logBookId;
        const prevLogBookId = prevValues?.logBookId;
        
        // check if logbook id is changed, if yes, then update the contact count in both logbooks
        if (newLogBookId && prevLogBookId && prevLogBookId !== newLogBookId) {

          const q = query(
            collection(db, LogBookContactTable),
            where("logBookId", "==", newLogBookId)
          );
          const queryCount = await getCountFromServer(q);
        
          // Update the document in LogBookTable
          await updateDoc(doc(db, LogBookTable, `${newLogBookId}`), {
            contactCount: queryCount?.data()?.count || 0,
          });


          // Update the document in LogBookTable
          const q2 = query(
            collection(db, LogBookContactTable),
            where("logBookId", "==", prevLogBookId)
          );
          const queryCount2 = await getCountFromServer(q2);
        
          // Update the document in LogBookTable
          await updateDoc(doc(db, LogBookTable, `${prevLogBookId}`), {
            contactCount: queryCount2?.data()?.count || 0,
          });
        }

        // check if grid/country is changed, if yes, then calculate new distance and coordinates
        if (
          (data?.grid && data?.grid !== prevValues?.grid) || // grid is changed, need new distance and coordinates
          (data?.grid === "" && prevValues?.grid) || // grid is removed, need new coordinates and distance
          (data?.grid === "" &&
            prevValues?.grid === "" &&
            data?.country &&
            data?.country !== prevValues?.country) || //no grid, country is changed
          (data?.grid === "" &&
            prevValues?.grid === "" &&
            data?.country === "" &&
            prevValues?.country) || // no grid, country is removed
          (data?.userGrid && data?.userGrid !== prevValues?.userGrid) // my grid is changed, need new distance and coordinates
        ) {
          calculateContactDistance({
            newData: data,
            prevData: prevValues,
          });
        }

        if (logbook?.isWFDLogBook && contactId){
          updateContestContactPoints({
            contactId: contactId,
            prevValues,
          }).then(()=>{
            if (cb){
              cb(true);
            }
          })
        } else {
          if (cb){
            cb(true);
          }
        }

        allErrorHandler("updated successfully");
        return true;
      } else {
        allErrorHandler("something went wrong");
        if (cb){
          cb(false);
        }
        return false;
      }
    } catch (e) {
      console.log("ERROR WHILE SAVING NEW LOGBOOK CONTACT: ", e);
      allErrorHandler("something went wrong");
      if (cb){
        cb(false);
      }
      return false;
    }
  },
  editUserLogBookData: async (data, logBookId) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;

      if (uid) {
        const contactRef = doc(db, LogBookTable, logBookId);
        await setDoc(contactRef, data, { merge: true });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR : ", e);
      return false;
    }
  },
  batchUpdateNameByLogBookId: async (logBookId, newName) => {
    try {
      const q = query(
        collection(db, LogBookContactTable),
        where("logBookId", "==", logBookId)
      );

      const querySnapshot = await getDocs(q);
      const totalDocs = querySnapshot.size;

      if (totalDocs === 0) {
        console.log("No documents found with the given logBookId.");
        return;
      }

      console.log(
        `Found ${totalDocs} documents. Updating in batches with new value: ${newName}`
      );

      let batch = writeBatch(db);
      let batchCount = 0;
      let index = 0;
      querySnapshot.forEach((docSnapshot) => {
        const docRef = doc(db, LogBookContactTable, docSnapshot.id);
        batch.update(docRef, { "logBook.name": newName }); // Update the name field
        batchCount++;

        // Commit the batch after 500 operations or if it's the last document
        if (batchCount === 500 || index++ === totalDocs - 1) {
          batch.commit();
          console.log(`Batch of ${batchCount} documents updated.`);
          batch = writeBatch(db);
          batchCount = 0;
        }
      });

      console.log("All documents updated successfully!");
    } catch (error) {
      console.error("Error updating documents: ", error);
    }
  },
  batchUpdateContestNameByContestId: async (contestId, newName) => {
    try {
      const q = query(
        collection(db, LogBookContactTable),
        where("contestId", "==", contestId)
      );

      const querySnapshot = await getDocs(q);
      const totalDocs = querySnapshot.size;

      if (totalDocs === 0) {
        console.log("No documents found with the given contestId.");
        return;
      }

      console.log(
        `Found ${totalDocs} documents. Updating in batches with new value: ${newName}`
      );

      let batch = writeBatch(db);
      let batchCount = 0;
      let index = 0;
      querySnapshot.forEach((docSnapshot) => {
        const docRef = doc(db, LogBookContactTable, docSnapshot.id);
        batch.update(docRef, { contestName: newName }); // Update the name field
        batchCount++;

        // Commit the batch after 500 operations or if it's the last document
        if (batchCount === 500 || index++ === totalDocs - 1) {
          batch.commit();
          console.log(`Batch of ${batchCount} documents updated.`);
          batch = writeBatch(db);
          batchCount = 0;
        }
      });

      console.log("All documents updated successfully!");
    } catch (error) {
      console.error("Error updating documents: ", error);
    }
  },
  deleteUserLogBookContactData: async (
    contactId,
    logBookId,
    userProfile,
    contact
  ) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;

      if (uid) {
        const batch = writeBatch(db);

        const contactRef = doc(db, LogBookContactTable, contactId);
        batch.delete(contactRef); // Add delete operation to batch

        if (logBookId) {
          const logBookRef = doc(db, LogBookTable, `${logBookId}`);
          batch.set(
            logBookRef,
            { contactCount: increment(-1) },
            { merge: true }
          );

          const userDataRef = doc(db, UserDataTable, `${uid}`);
          if (contact.adiImported) {
            batch.set(
              userDataRef,
              { numberOfContactsImported: increment(-1) },
              { merge: true }
            );
          } else {
            batch.set(
              userDataRef,
              { numberOfContacts: increment(-1) },
              { merge: true }
            );
          }

          const updatedUserData = {
            bands: userProfile.bands || {},
            modes: userProfile.modes || {},
          };
          const userBands = updatedUserData.bands;
          const userModes = updatedUserData.modes;

          if (contact.band && userBands[contact.band]) {
            userBands[contact.band]--;
          }

          if (contact.userMode && userModes[contact.userMode]) {
            userModes[contact.userMode]--;
          }

          updatedUserData.bands = userBands;
          updatedUserData.modes = userModes;

          batch.set(userDataRef, updatedUserData, { merge: true });
        }
        // if (contact.duplicate) {
        //   const band = contact.band;
        //   const rstSent = contact.rstSent;
        //   const rstRCVD = contact.rstRCVD;

        //   const time = contact.time;
        //   const date = contact.date;
        //   const contactTime = new Date(`${date}T${time}`).getTime();

        //   const logBookContactRef = collection(db, "LogBookContact");
        //   const duplicateContactsQuery = query(
        //     logBookContactRef,
        //     where("theirCallsign", "==", contact.theirCallsign),
        //     where("band", "==", band),
        //     where("rstSent", "==", rstSent),
        //     where("rstRCVD", "==", rstRCVD),
        //     where("uid", "==", contact.uid)
        //   );

        //   const duplicateContactsDocRef = await getDocs(duplicateContactsQuery);
        //   const duplicateContacts: any[] = [];
        //   duplicateContactsDocRef.docs.forEach((doc) => {
        //     const data: any = { id: doc.id, ...doc.data() };
        //     const existingContactTime = new Date(
        //       `${data.date}T${data.time}`
        //     ).getTime();
        //     const timeDiff = Math.abs(contactTime - existingContactTime);

        //     if (timeDiff <= 15 * 60000 && doc.id !== contactId) {
        //       duplicateContacts.push({
        //         ...data,
        //       });
        //     }
        //   });
        //   if (duplicateContacts.length === 1) {
        //     const dupeContactRef = doc(
        //       db,
        //       LogBookContactTable,
        //       `${duplicateContacts[0].id}`
        //     );
        //     batch.set(dupeContactRef, { duplicate: false }, { merge: true });
        //   }
        // }
        const mobileOfflineSyncData = {
          uid: uid,
          itemId: contactId,
          collection: LogBookContactTable,
          operationType: "DELETE",
          timestamp: serverTimestamp(),
          mobileSyncStatus: "PENDING",
        };
        //add to offline sync
        const offlineSyncRef = collection(db, OfflineMobileSync);
        batch.set(doc(offlineSyncRef), mobileOfflineSyncData);

        await batch.commit(); // Commit the batch

        allErrorHandler("deleted successfully");
        return true;
      } else {
        allErrorHandler("something went wrong");
        return false;
      }
    } catch (e) {
      allErrorHandler("something went wrong");
      return false;
    }
  },
  deleteUserLogBookData: async (logBookId, contestId) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        //check the logbook contact count and if they are less than 500 delete all contacts matching that logbook id
        // using a batch and then delete the logbook if the contacts are more than 500 then just
        // mark the logbook for deletion and initiate a delete process for the contacts
        const q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", logBookId)
        );
        const docCount = await getCountFromServer(q);
        const count = docCount?.data()?.count || 0;
        if (count <= 500) {
          const querySnapshot = await getDocs(q);
          const batch = writeBatch(db);
          querySnapshot.forEach((docData) => {
            const contactRef = doc(db, LogBookContactTable, docData.id);
            batch.delete(contactRef);
          });
          await batch.commit();
          //delete the actual logbook
          const logBookRef = doc(db, LogBookTable, logBookId);
          await deleteDoc(logBookRef);
          showToast({
            message: "Logbook Deleted, all contacts have been deleted.",
            type: ToastTypes.SUCCESS,
          });
        } else {
          await LogBookService.editUserLogBookData(
            { deleteInProgress: true },
            logBookId
          );
          deleteLogbookRecords({
            logBookId: logBookId,
            userId: uid,
          });

          showToast({
            message:
              "Delete initiated, this might take sometime if you have a large collection.",
            type: ToastTypes.WARN,
          });
        }
        if (contestId) {
          //if the logbook is associated with a contest then remove the user from the contest

          const contestRef = doc(db, ContestTable, `${contestId}`);
          const enrolledUsersCollectionRef = collection(
            contestRef,
            EnrolledUsers
          );

          const contestEnrollRef = doc(enrolledUsersCollectionRef, `${uid}`);
          await deleteDoc(contestEnrollRef);
        }
        return true;
      } else {
        console.log("DELETE ERROR : NO UID");

        allErrorHandler("something went wrong");
        return false;
      }
    } catch (e) {
      console.log("DELETE ERROR : ", e);
      allErrorHandler("something went wrong");
      return false;
    }
  },
  getAllUserContacts: async (uid) => {
    let arr: LogBookContactModel[] = [];
    try {
      // if (uid) {
      const q = query(
        collection(db, LogBookContactTable),
        where("adiImported", "==", false),
        // where("uid", "==", uid)
        orderBy("contactTimeStamp", "desc"),
        limit(200)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        querySnapshot.forEach((doc) => {
          let data: LogBookContactModel = {
            id: doc.id,
            ...doc.data(),
          };
          arr.push(data);
        });
      }
      return arr;
      // } else {
      //   return arr;
      // }
    } catch (e) {
      console.log("getAllUserContacts ERROR : ", e);
      return [];
    }
  },
  getAllUserContactsById: async (payload) => {
    let arr: LogBookContactModel[] = [];

    try {
      if (payload.uid) {
        let q;
        if (payload.data) {
          if (payload?.data?.showImported) {
            q = query(
              collection(db, LogBookContactTable),
              where("uid", "==", payload.uid),
              orderBy("contactTimeStamp", "desc"),
              limit(payload?.data?.limit || 50)
            );
          } else {
            q = query(
              collection(db, LogBookContactTable),
              where("uid", "==", payload.uid),
              where("adiImported", "==", false),
              orderBy("contactTimeStamp", "desc"),
              limit(payload?.data?.limit || 50)
            );
          }
        } else {
          q = query(
            collection(db, LogBookContactTable),
            where("uid", "==", payload.uid),
            orderBy("contactTimeStamp", "desc"),
            limit(50)
          );
        }

        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: LogBookContactModel = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
        }
        return arr;
      } else {
        return arr;
      }
    } catch (e) {
      console.log("getAllUserContactsById ERROR : ", e);
      return [];
    }
  },
  getUserLogBookContacts: async (uid, data) => {
    let arr: LogBookContactModel[] = [];
    try {
      if (uid && data.logBookId) {
        let q;

        if (data.showImported) {
          q = query(
            collection(db, LogBookContactTable),
            where("uid", "==", uid),
            where("logBookId", "==", data.logBookId),
            orderBy("contactTimeStamp", "desc"),
            limit(data.limit || 50)
          );
        } else {
          q = query(
            collection(db, LogBookContactTable),
            where("uid", "==", uid),
            where("logBookId", "==", data.logBookId),
            where("adiImported", "==", false),
            orderBy("contactTimeStamp", "desc"),
            limit(data.limit || 50)
          );
        }
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: LogBookContactModel = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
        }
        return arr;
      } else {
        return arr;
      }
    } catch (e) {
      console.log("getUserLogBookContacts ERROR : ", e);
      return [];
    }
  },
  fetchCallSignData: async (callSign) => {
    try {
      const [baseCallSign] = callSign.split("/");
      const data = await fetch(
        `https://api.hamdb.org/v1/${baseCallSign}/json/wrl`
      );
      let rawResponse = await data.text(); // Get raw text response

      // Sanitize JSON to remove unescaped double quotes within values
      rawResponse = rawResponse.replace(
        /:(\s*?)"(.*?)"(?=[,}])/gs,
        (match, p1, p2) => {
          const sanitizedValue = p2.replace(/(?<!\\)"/g, ""); // Remove all unescaped double quotes
          return `:${p1}"${sanitizedValue}"`;
        }
      );

      const response = JSON.parse(rawResponse);
      if (response?.hamdb?.messages?.status === "OK") {
        return response;
      }
      return null;
    } catch (e) {
      console.log("HAM DB ERROR : ", e);
      return null;
    }
  },
  getPaginatedLogbookContactsById: async (conditions, userId) => {
    try {
      const uid: string = auth.currentUser?.uid || userId;
      let q: any;
      if (conditions?.move === -1 && conditions?.firstDoc) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", conditions?.logbookId || ""),
          orderBy(
            "contactTimeStamp",
            conditions?.dateOrder ? conditions?.dateOrder : "desc"
          ),
          endBefore(conditions?.firstDoc["contactTimeStamp"]),
          limitToLast(50)
        );
      } else if (conditions?.move === 0) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", conditions?.logbookId || ""),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          startAt(conditions?.firstDoc["contactTimeStamp"]),
          limit(50)
        );
      } else if (conditions.move === -2) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", conditions?.logbookId || ""),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          limit(50)
        );
      } else if (conditions?.lastDoc) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", conditions?.logbookId || ""),
          orderBy(
            "contactTimeStamp",
            conditions?.dateOrder ? conditions?.dateOrder : "desc"
          ),
          startAfter(conditions?.lastDoc["contactTimeStamp"]),
          limit(50)
        );
      } else {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", conditions?.logbookId || ""),
          orderBy(
            "contactTimeStamp",
            conditions.dateOrder ? conditions.dateOrder : "desc"
          ),
          limit(50)
        );
      }

      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const contacts: LogBookContactModel[] = querySnapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...(doc.data() as LogBookContactModel),
            };
          }
        );
        const countQuery = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("logBookId", "==", conditions?.logbookId || ""),
          orderBy("contactTimeStamp", "desc")
        );
        const count = await getCountFromServer(countQuery);
        return {
          contacts: contacts,
          count: count?.data()?.count,
          lastDoc: contacts[contacts?.length - 1],
          firstDoc: contacts[0],
          page: conditions?.page || 1,
        };
      } else {
        return null;
      }
    } catch (e) {
      console.log("getPaginatedLogbookContactsById ERROR : ", e);
      return null;
    }
  },
  getAdiLogbooks: async () => {
    const uid: string | undefined = auth.currentUser?.uid;
    let arr: LogBookModel[] = [];
    try {
      const q = query(
        collection(db, LogBookTable),
        where("adiImported", "==", true),
        where("uid", "==", uid),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        querySnapshot.forEach((doc) => {
          let data: LogBookModel = {
            id: doc.id,
            ...doc.data(),
          };
          arr.push(data);
        });
      }
      return arr;
    } catch (e) {
      return [];
    }
  },
  fetchContactDetail: async (contactId) => {
    try {
      const contactRef = doc(db, LogBookContactTable, contactId);
      const contactData: DocumentData = await getDoc(contactRef);
      if (contactData.exists()) {
        return {
          ...contactData.data(),
          id: contactId,
        } as LogBookContactModel;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  },
  editContact: async (contactId, data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;

      if (uid) {
        const contactRef = doc(db, LogBookContactTable, contactId);
        await setDoc(contactRef, data, { merge: true });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  searchLogbookContacts: async (payload) => {
    let arr: LogBookContactModel[] = [];
    try {
      let q = query(
        collection(db, LogBookContactTable),
        where("uid", "==", payload.uid),
        where("callSignSearchIndex", "array-contains", payload.searchString),
        orderBy("contactTimeStamp", "desc"),
        limit(50)
      );
      if (payload?.logbookId) {
        q = query(q, where("logBookId", "==", payload.logbookId));
      }
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        querySnapshot.forEach((doc) => {
          let data: LogBookContactModel = {
            id: doc.id,
            ...doc.data(),
          };
          arr.push(data);
        });
      }

      return arr;
    } catch (e) {
      console.log("searchLogbookContacts ERROR : ", e);
      return [];
    }
  },
  getHomeDailyLeaders: async () => {
    try {
      const data = await getDailyLeaders();

      return data;
    } catch (e) {
      return null;
    }
  },
  shareQsoMap: async (data) => {
    try {
      const qsoMapRef = doc(collection(db, QsoMapShares));
      await setDoc(qsoMapRef, data);
      return qsoMapRef.id;
    } catch (e) {
      return null;
    }
  },
  fetchSavedLocations: async (uid) => {
    try {
      let arr: SavedLocation[] = [];
      if (uid) {
        const q = query(
          collection(db, SavedLocations),
          where("uid", "==", uid),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: any = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
        }
      }
      return arr;
    } catch (e) {
      console.log("ERROR fetchSavedLocations : ", e);
      return null;
    }
  },
  addSavedLocation: async (data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      const { setAsHome, ...rest } = data;
      if (uid) {
        if (data.id) {
          //edit saved location
          const savedLocationRef = doc(db, SavedLocations, data.id);
          await setDoc(savedLocationRef, rest, { merge: true });
          return true;
        } else {
          const savedLocationRef = collection(db, SavedLocations);
          const response = await addDoc(savedLocationRef, {
            ...rest,
            uid: uid,
            timestamp: serverTimestamp(),
          });
          return response?.id ? response?.id : true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR addSavedLocation : ", e);
      return false;
    }
  },
  deleteSavedLocation: async (id) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const savedLocationRef = doc(db, SavedLocations, id);
        await deleteDoc(savedLocationRef);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR deleteSavedLocation : ", e);
      return false;
    }
  },
  addRadio: async (data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        if (data.id) {
          //edit saved radio
          const savedRadioRef = doc(db, UserRadios, data.id);
          await setDoc(savedRadioRef, data, { merge: true });
          return true;
        } else {
          const radioRef = doc(collection(db, UserRadios));
          await setDoc(radioRef, {
            ...data,
            uid: uid,
            timestamp: serverTimestamp(),
          });
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR addRadio : ", e);
      return false;
    }
  },
  fetchRadios: async (uid) => {
    try {
      let arr: Radios[] = [];
      if (uid) {
        const q = query(
          collection(db, UserRadios),
          where("uid", "==", uid),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: any = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
        }
      }
      return arr;
    } catch (e) {
      console.log("ERROR fetchRadios : ", e);
      return null;
    }
  },
  fetchAntennas: async (uid) => {
    try {
      let arr: Antenna[] = [];
      if (uid) {
        const q = query(
          collection(db, UserAntennas),
          where("uid", "==", uid),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: any = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
        }
      }
      return arr;
    } catch (e) {
      console.log("ERROR fetchAntennas : ", e);
      return null;
    }
  },
  addAntenna: async (data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        if (data.id) {
          //edit saved antenna
          const savedAntennaRef = doc(db, UserAntennas, data.id);
          await setDoc(savedAntennaRef, data, { merge: true });
          return true;
        } else {
          const antennaRef = doc(collection(db, UserAntennas));
          await setDoc(antennaRef, {
            ...data,
            uid: uid,
            timestamp: serverTimestamp(),
          });
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR addAntenna : ", e);
      return false;
    }
  },
  deleteRadios: async (id) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const radioRef = doc(db, UserRadios, id);
        await deleteDoc(radioRef);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR deleteRadios : ", e);
      return false;
    }
  },
  deleteAntenna: async (id) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const antennaRef = doc(db, UserAntennas, id);
        await deleteDoc(antennaRef);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR deleteAntenna : ", e);
      return false;
    }
  },
  fetchAddContactLogPopupSummary: async (conditions) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      let q: any;

      if (conditions?.theirCallsign && uid) {
        q = query(
          collection(db, LogBookContactTable),
          where("uid", "==", uid),
          where("theirCallsign", "==", conditions?.theirCallsign),
          orderBy("contactTimeStamp", "desc")
        );
      } else {
        return null;
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot !== null || querySnapshot !== undefined) {
        const contacts: LogBookContactModel[] = querySnapshot.docs.map(
          (doc) => {
            return {
              id: doc.id,
              ...(doc.data() as LogBookContactModel),
            };
          }
        );

        return {
          noOfContacts: contacts?.length || 0,
          lastContact: contacts?.[0]?.date
            ? getTimeFromNow(`${contacts?.[0]?.date} ${contacts?.[0]?.time}`)
            : "",
        };
      } else {
        return null;
      }
    } catch (e) {
      console.log("fetchAddContactLogPopupSummary ERROR : ", e);

      return null;
    }
  },
  fetchStations: async (uid) => {
    try {
      let arr: Stations[] = [];
      if (uid) {
        const q = query(
          collection(db, UserStations),
          where("uid", "==", uid),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: any = {
              id: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
        }
      }
      return arr;
    } catch (e) {
      console.log("ERROR fetchAntennas : ", e);
      return null;
    }
  },
  addStation: async (data) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        if (data.id) {
          //edit saved antenna
          const savedStationRef = doc(db, UserStations, data.id);
          await setDoc(savedStationRef, data, { merge: true });
          return true;
        } else {
          const stationRef = doc(collection(db, UserStations));
          await setDoc(stationRef, {
            ...data,
            uid: uid,
            timestamp: serverTimestamp(),
          });
          return true;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR addAntenna : ", e);
      return false;
    }
  },
  deleteStation: async (id) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const stationRef = doc(db, UserStations, id);
        await deleteDoc(stationRef);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("ERROR deleteAntenna : ", e);
      return false;
    }
  },
  getLogbookContactCount: async (uid, logBookId) => {
    const q = query(
      collection(db, LogBookContactTable),
      where("uid", "==", uid),
      where("logBookId", "==", logBookId)
    );
    const queryCount = await getCountFromServer(q);
    const count = queryCount?.data()?.count || 0;
    return count;
  },

  getQSOManagerContacts: async (data) => {
    try {
      let result: any = null;
      const response: { data: any } = await getQSOManagerContacts(data);

      if (response && response.data) {
        result = response.data;
      }
      return result;
    } catch (e) {
      return null;
    }
  },
  checkForDuplicate: async (contactData: LogBookContactModel, options?: any) => {
    try {
      const {
        theirCallsign,
        band,
        rstSent,
        rstRCVD,
        myCallSign,
        uid,
        date,
        time,
        frequency,
        userMode,
      } = contactData;

      if (options && options?.isWFDLogBook && options?.logBookId) {

        if( !band || !userMode ) {
          return null
        }

        // Query with all required conditions
        const logBookContactRef = collection(db, LogBookContactTable);
        const duplicateContactsQuery = query(
          logBookContactRef,
          where("uid", "==", uid),
          where("band", "==", band),
          where("userMode", "==", userMode),
          where("theirCallsign", "==", theirCallsign),
          where("myCallSign", "==", myCallSign),
          where("logBookId", "==", options?.logBookId),
          orderBy("timestamp", "desc"),
          limit(1)
        );

        const duplicateContactsDocRef = await getDocs(duplicateContactsQuery);

        if (duplicateContactsDocRef.empty) {
          return null;
        }

        const data = duplicateContactsDocRef.docs[0].data();

        return {
          id: duplicateContactsDocRef.docs[0].id,
          band: data.band,
          timestamp: data.timestamp,
          date: data.date,
          time: data.time
        };
  
      }
      else {

        const contactTime = new Date(`${date}T${time}Z`).getTime();

        // Query with all required conditions
        const logBookContactRef = collection(db, LogBookContactTable);
        const duplicateContactsQuery = query(
          logBookContactRef,
          where("uid", "==", uid),
          where("band", "==", band),
          where("userMode", "==", userMode),
          where("theirCallsign", "==", theirCallsign),
          where("myCallSign", "==", myCallSign),
          orderBy("timestamp", "desc"),
          limit(1)
        );

        const duplicateContactsDocRef = await getDocs(duplicateContactsQuery);

        if (duplicateContactsDocRef.empty) {
          return null;
        }

        // Check for duplicates within 15 minutes on same UTC date
        const duplicateContacts = duplicateContactsDocRef.docs
          .map(doc => {
            const data = doc.data();
            const existingContactTime = new Date(`${data.date}T${data.time}Z`).getTime();
            const isSameUTCDate = new Date(data.date).toISOString().slice(0, 10) === date;

            if (
              isSameUTCDate &&
              Math.abs(contactTime - existingContactTime) <= 15 * 60000 // 15 minutes in milliseconds
            ) {
              return {
                id: doc.id,
                band: data.band,
                timestamp: data.timestamp,
                date: data.date,
                time: data.time
              };
            }
            return null;
          })
          .filter(Boolean);

        // Return the most recent duplicate if any found
        return duplicateContacts.length > 0 ? duplicateContacts[0] : null;
      }


    } catch (error) {
      console.error("Error checking for duplicate:", error);
      return null;
    }
  },
  getAnalytics: async (data) => {
    try { 
      let result: any = null;
      const response: { data: any } = await getAnalyticsData(data);

      if (response && response.data) {
        result = response.data;
      }
      return result;
    } catch (e) {
      console.error('Error getting analytics:', e);
      return null;
    }
  },

  // deleteQSOManagerContact: async (contactId: string) => {
  //   await deleteLogbookRecords(contactId);
  // }
};

export default LogBookService;
