import Icons from "assets/Icons";
import { HeaderProps } from "types/Component";
import { CgMenu } from "react-icons/cg";
import useConfig from "hooks/config.hook";
import useProfile from "hooks/profile.hook";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import { globalNavigate } from "routes/GlobalRoutes";
import { useEffect, useState } from "react";
import {
  headerDropdown,
  headerDropdownLogoutMenu,
  headerDropdownMenus,
  headerDropdownUserSettings,
} from "constants/NavMenus";
import CustomBorderButton from "components/Button/CustomBorderButton";
import { Link, useLocation } from "react-router-dom";
import { MY_PROFILE_ROUTE } from "constants/Links";
import useAuth from "hooks/auth.hook";
import CustomValidation from "helpers/Validation";
import NotificationBanner from "components/NotificationBanner";
import { useDispatch, useSelector } from "react-redux";
import { getUserNormalNotificationsSelector } from "store/Reducers/notification.reducer";
import { markReadUserNormalNotifications } from "store/Actions/notification.actions";
import { UserAvatar } from "components/UserAvatar";
import { CallSignSearchbar } from "components/CallSignSearchbar";
import LottieFiles from "assets/LottieFiles";
import Lottie from "lottie-react";
import { useUserActivity } from "hooks/useUserActivity.hook";
import { Tooltip } from "components/Tooltip";
import { UserActivityTypeDisplayName } from "components/Table/ActivityLogTable/TableCol";
import useTheme from "hooks/useTheme.hook";
import ClockDisplay from "./ClockDisplay";

import { getImpersonationDataSelector } from "store/Reducers/profile.reducer";
import { setImpersonationData } from "store/Actions/profile.actions";
import { auth } from "firebase-config";
import SubHeader from "./SubHeader";
function Header({ title, subTitle, blueText }: HeaderProps) {
  const {
    handleNavBarVisibility,
    getSubscriptionLevelButtonColor,
    getSubscriptionLevelButtonBgColor,
    headerDropDownNavMenu,
    setHeaderDropDownNavMenu,
    handlePrimaryModal,
  } = useConfig();
  const { logOut } = useAuth();
  const { userProfile, memberStatus } = useProfile();
  const [userDropdown, setUserDropdown] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const userNormalNotifications = useSelector(
    getUserNormalNotificationsSelector
  );
  const impersonationData = useSelector(getImpersonationDataSelector);

  const location = useLocation();
  const dispatch = useDispatch();
  const { theme, toggleTheme } = useTheme();
  const { userActivityStatus, userActivityType, userActivityId } =
    useUserActivity();

  const handleDropdownButton = (item: any) => {
    if (
      item?.link &&
      !location.pathname.includes(`${item.link}/${userProfile?.callSign}`) &&
      item.link.includes(MY_PROFILE_ROUTE) &&
      location.pathname.includes(MY_PROFILE_ROUTE)
    ) {
      window.location.href = `${item.link}/${CustomValidation.encodeSlash(
        userProfile?.callSign ? userProfile?.callSign : ""
      )}`;
    } else if (item.link === MY_PROFILE_ROUTE) {
      globalNavigate(
        `${item.link}/${CustomValidation.encodeSlash(
          userProfile?.callSign ? userProfile?.callSign : ""
        )}`,
        {
          state: userProfile?.uid,
        }
      );
    } else if (item.link) {
      globalNavigate(item.link);
    } else if (item.title === "Log Out") {
      handlePrimaryModal({
        title: "Are you sure you want to sign out?",
        type: "Signout",
        onCancelPressed: () => handlePrimaryModal(null),
        onSubmitPressed: () => {
          logOut();
          handlePrimaryModal(null);
        },
        submitText: "Signout",
        cancelText: "Cancel",
      });
    }
  };

  useEffect(() => {
    if (userProfile?.subscriptionStatus === "active") {
      setHeaderDropDownNavMenu([
        ...headerDropdownMenus,
        headerDropdownLogoutMenu,
      ]);
    } else {
      setHeaderDropDownNavMenu([
        ...headerDropdownMenus.slice(0, 2),
        headerDropdown,
        headerDropdownUserSettings,
        headerDropdownLogoutMenu,
      ]);
    }
  }, [userProfile]);

  const onNotificationToggle = () => {
    if (showNotification && getUnReadNotifications() > 0) {
      dispatch(markReadUserNormalNotifications());
    }
    setShowNotification(!showNotification);
  };

  const getUnReadNotifications = () => {
    let count = 0;
    if (userNormalNotifications) {
      userNormalNotifications.forEach((notification) => {
        if (!notification.seen) {
          count++;
        }
      });
    }

    return count;
  };

  return (
    <>
      <header
        className={`h-14 lg:h-16 w-full flex items-center justify-between py-2 px-2 sm:px-7 lg:px-11 relative z-30 ${
          theme === "dark" ? "glassmorphism" : "bg-white"
        }`}
      >
        <div
          className="bg-[#1F1F3F] p-2 block lg:hidden rounded"
          onClick={() => handleNavBarVisibility(true)}
        >
          <CgMenu className="text-white" />
        </div>

        <div className="flex w-[60%] md:w-[60%] xl:w-[40%]">
          <CallSignSearchbar />
        </div>

        <div className="flex items-center relative z-30">
          <Tooltip
            message={theme === "dark" ? "Set Light Mode" : "Set Dark Mode"}
            className="w-[100px]"
          >
            <div
              className="flex mr-3 lg:mr-7 w-7 h-7 cursor-pointer"
              onClick={toggleTheme}
            >
              <img
                className={`w-7 h-7 cursor-pointer ${
                  theme === "dark" ? "invert" : ""
                }`}
                src={theme === "dark" ? Icons.SunIcon : Icons.MoonIcon}
                alt=""
              />
            </div>
          </Tooltip>

          {userActivityStatus === "In Progress" ? (
            <Tooltip
              message={`${UserActivityTypeDisplayName[userActivityType]} is in-progress`}
              className="w-[180px]"
            >
              <div
                className="hidden lg:flex mr-7 w-7 h-7 cursor-pointer"
                onClick={() =>
                  globalNavigate(
                    `/log-contacts/activity-details?activity_id=${userActivityId}`
                  )
                }
              >
                <Lottie animationData={LottieFiles.Sync} loop={true} />
              </div>
            </Tooltip>
          ) : null}
          {userProfile ? (
            <div
              className="mr-4 sm:mr-7 w-7 h-7 relative"
              onClick={() => onNotificationToggle()}
            >
              <img
                className="w-7 h-7 cursor-pointer invert dark:filter-none dark:invert-0"
                src={Icons.HeaderNotification}
                alt=""
              />
              {getUnReadNotifications() === 0 ? null : (
                <div className="absolute w-[15px] h-[15px] rounded-full -top-1 -right-1 bg-[red] flex justify-center align-center">
                  <p className="font-[Play] text-[10px] text-white">
                    {getUnReadNotifications()}
                  </p>
                </div>
              )}
            </div>
          ) : null}

          {userProfile ? (
            <div
              className="flex items-center relative cursor-pointer"
              onMouseEnter={() => setUserDropdown(true)}
              onMouseLeave={() => setUserDropdown(false)}
            >
              {userProfile?.profilePic ? (
                <img
                  className="w-8 h-8 sm:mr-3 rounded-full object-cover"
                  src={userProfile?.profilePic || ""}
                  alt=""
                />
              ) : (
                <UserAvatar className="h-8 w-8 sm:mr-3" isCurrentUser />
              )}
              <div className="hidden lg:flex flex-col justify-center mr-2">
                <h4 className="font-Play text-base dark:text-white text-[#2b2e48]">
                  {userProfile?.callSign}
                </h4>
                {/* <p className="font-Play text-xs text-white">
              </p> */}
                <div className="w-auto">
                  <CustomBorderButton
                    text={memberStatus}
                    onClick={() => {}}
                    height="h-[24px]"
                    className=""
                    childHeight="h-[57px]"
                    childClassName="text-[12px] font-bold px-2"
                    fromBg={getSubscriptionLevelButtonColor(
                      "from",
                      memberStatus
                    )}
                    viaBg={getSubscriptionLevelButtonColor("via", memberStatus)}
                    toBg={getSubscriptionLevelButtonColor("to", memberStatus)}
                    childBgColor={getSubscriptionLevelButtonBgColor(
                      memberStatus
                    )}
                  />
                </div>
              </div>
              <img
                className="hidden lg:flex w-6 h-6"
                src={Icons.ChevronDown}
                alt=""
              />
              {userDropdown ? (
                <div
                  className=" min-w-[150px] w-[100%] absolute top-[100%] right-0 bg-white"
                  // onMouseEnter={() => setUserDropdown(true)}
                  // onMouseLeave={() => setUserDropdown(false)}
                >
                  {headerDropDownNavMenu.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className="border-solid border-white border-[0.5px] pl-3 w-full text-white bg-[#4D4E80] text-xs font-[Play] py-2 cursor-pointer hover:glassmorphism"
                        onClick={() => handleDropdownButton(item)}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="hidden lg:flex w-[320px] pt-0 sm:pt-5">
              <BorderButtonPrimary
                className="sm:mb-0"
                text="Create a Free Account"
                onClick={() => globalNavigate("/register")}
              />
            </div>
          )}
        </div>
        {showNotification ? (
          <NotificationBanner onNotificationToggle={onNotificationToggle} />
        ) : null}
    </header>

    {impersonationData ? (
        <div className="bg-[#FF0000] w-full h-[50px] px-5 flex items-center justify-around">
          <p className="text-white font-bold text-xl font-[PLay]">
            You are impersonating user: {impersonationData.userImpersonated}
          </p>
          <button
            className="bg-white text-green px-4 py-2 rounded-full font-bold"
            onClick={() => {
              handlePrimaryModal({
                title: "Are you sure you want to switch back to you account?",
                type: "Switch Back?",
                onCancelPressed: () => handlePrimaryModal(null),
                onSubmitPressed: async () => {
                  await auth.signOut();
                  dispatch(setImpersonationData(null));
                  handlePrimaryModal(null);
                },
                submitText: "Yes, Switch Back!",
                cancelText: "Cancel",
              });
            }}
          >
            Change Back To My Account
          </button>
        </div>
      ) : null}

      <SubHeader />
    </>
  );
}

export default Header;
